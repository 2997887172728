import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { getMyCompanyById } from '@/actions/company'
import PageLoader from '@/components/Common/Loader/PageLoader'
import BlockEditInfo from '@/components/Company/BlockEditInfo'
import BlockEditAbout from '@/components/Company/BlockEditAbout'
import BlockEditMembers from '@/components/Company/BlockEditMembers'
import BlockEditOffers from '@/components/Company/BlockEditOffers'

const CompanyEditPage = () => {
    const { id } = useParams()
    const { pathname } = useLocation()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [mode, setMode] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const myCompanyLoading = useSelector(state => state.company.myCompanyLoading)
    const myCompany = useSelector(state => state.company.myCompany)

    useEffect(() => {
        if (id) {
            dispatch(getMyCompanyById(id))
            setMode('edit')
        } else if (pathname) {
            if (pathname.replace('/company/', '') === 'create') {
                setMode('create')
            }
        }
    }, [id, /*pathname, */dispatch])

    useEffect(() => {
        if (!myCompany && !myCompanyLoading) {
        	navigate('/404')
        } else {
            if (myCompany || mode === 'create') {
                setTimeout(() => setLoaded(true), 500)
            }
        }
    }, [myCompanyLoading, myCompany, mode, navigate])

	return (
		<>
            <PageLoader loaded={loaded} />
			<BlockEditInfo company={myCompany} mode={mode} />
            {mode === 'edit' && (<>
    			<BlockEditAbout company={myCompany} />
    			<BlockEditMembers company={myCompany} />
                <BlockEditOffers company={myCompany} />
            </>)}
		</>
	)
}

export default CompanyEditPage
