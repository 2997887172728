import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { saveProject, savePartner } from '@/actions/project'
import { generateRandomString } from '@/utils/content.utils'
import arrow from '@/assets/images/search-arrow-down.svg'
import { checkImageType, ERROR_NOT_ALLOWED_IMAGE } from '@/utils/upload.utils'
import { STORAGE_URL } from '@/constants/api'
import ConfirmButton from '@/components/Common/Edit/ConfirmButton'

import './styles.css'

const BlockEditSupport = ({ project }) => {
	const dispatch = useDispatch()
    const imageUploadInputRef = useRef()

	const initPartner = { key: null, name: '', logo: null }
	const [partners, setPartners] = useState(Array(10).fill(initPartner))
	const [data, setData] = useState({ budget: 0 })
	const [partner, setPartner] = useState(initPartner)
	const [uploadInfo, setUploadInfo] = useState(null)

	useEffect(() => {
		if (project) {
			const projectPartners = project.partners.map((partner) => ({
				key: partner.key || generateRandomString(),
				name: partner.name || '',
				logo: partner.logo || null
			}))

			setPartners([
				...projectPartners,
				...Array(10 - projectPartners.length).fill(initPartner)
			])

			setData({ budget: project.budget || 0 })
		}
	}, [project])

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    const getPartnerLogo = (path) => {
    	if (path) {
    		if (path.includes('https://')) {
    			return path
    		} else {
    			return `${STORAGE_URL}/${path}`
    		}
    	}
    	return null
    }

    const handlePartnerChange = (e) => {
        setPartner({ ...partner, name: e.target.value })
    }

    const handleImageUploadClick = () => {
        imageUploadInputRef.current.click()
    }

    const handleImageUpload = async (e) => {
        const image = e.target.files[0]
        if (checkImageType(image.type)) {
        	setUploadInfo(image.name)
        	setPartner({ ...partner, logo: image })
        } else {
        	setUploadInfo(ERROR_NOT_ALLOWED_IMAGE)
        }
    }

    const addPartner = () => {
    	if (partner.name && partner.logo) {
	        const formData = new FormData()
	        formData.append('key', generateRandomString())
	        formData.append('name', partner.name)
	        formData.append('logo', partner.logo)
	        formData.append('project_id', project.id)
    		dispatch(savePartner(formData, project.id))
    	}
    }

	const onSubmit = () => {
		dispatch(saveProject({ ...data, project_id: project.id }))
	}

	return (
		<div className="block block-support">
			<div className="block-wrap">
				<div className="block-heading red">
					<h2>support projektu</h2>
				</div>
				<div>
					<div className="block-editSupport_params">
						<div className="form-field">
							<span>budżet projektu</span>
							<input
								type="text"
								name="budget"
								placeholder="wpisz sumę"
								value={data.budget}
								onChange={handleInputChange}
							/>
							<span>PLN</span>
						</div>
					</div>
					<div className="block-editSupport_partners">
						<div className="block-editSupport_partners-list">
							<div className="block-editSupport_partners-list-title">
								Organizacje wspierające
							</div>
							<div className="block-editSupport_partners-list-items">
								{partners.map((partner, index) => {
									const { logo } = partner
									return (
										<div
											className="block-editSupport_partners-list-item"
											key={index}
										>
											{logo && <img src={getPartnerLogo(logo)} alt="" />}
										</div>
									)
								})}
								
							</div>
							
						</div>
						<div className="block-editSupport_partners-form">
							<div className="block-editSupport_partners-form-title">
								Dodaj nazwy i loga organizacji wspierających
							</div>
							<div className="block-editSupport_partners-form-body">
								<span className="form-field-arrow">
									<input
										type="text"
										name="partner"
										placeholder="wpisz lub wyszukaj organizację"
										value={partner.name}
										onChange={handlePartnerChange}
									/>
									<span className="button">
										<img src={arrow} alt="" />
									</span>
								</span>
								<span>{uploadInfo}</span>
								<div className="block-editSupport_partners-form-buttons">									
									<button
										type="button"
										className="btn btn-outline-primary btn-icon-left btn-icon-photo"
										onClick={handleImageUploadClick}
									>
										<span>DODAJ LOGO<br />ORGANIZAJI</span>
									</button>
									<input
						                type="file"
						                ref={imageUploadInputRef}
						                style={{ display: 'none' }}
						                onChange={handleImageUpload}
						            />
									<button
										type="button"
										className="btn btn-primary"
										onClick={addPartner}
									>
										<span>DODAJ ORGANIZACJĘ</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ConfirmButton onSubmit={onSubmit} />
			</div>
		</div>
	)
}

export default BlockEditSupport
