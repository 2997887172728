import ManageService from '@/services/manage.service'

export const SAVE_APPLICATION_REQUEST = 'SAVE_APPLICATION_REQUEST'
export const SAVE_APPLICATION_SUCCESS = 'SAVE_APPLICATION_SUCCESS'
export const SAVE_APPLICATION_FAILURE = 'SAVE_APPLICATION_FAILURE'
export const GET_DIRECTIONS_SUCCESS = 'GET_DIRECTIONS_SUCCESS'
export const GET_DIRECTIONS_FAILURE = 'GET_DIRECTIONS_FAILURE'
export const EDIT_TRANSACTION = 'EDIT_TRANSACTION'
export const SAVE_TRANSACTION_REQUEST = 'SAVE_TRANSACTION_REQUEST'
export const SAVE_TRANSACTION_SUCCESS = 'SAVE_TRANSACTION_SUCCESS'
export const SAVE_TRANSACTION_FAILURE = 'SAVE_TRANSACTION_FAILURE'
export const GET_DOCUMENT_TYPES_SUCCESS = 'GET_DOCUMENT_TYPES_SUCCESS'
export const GET_DOCUMENT_TYPES_FAILURE = 'GET_DOCUMENT_TYPES_FAILURE'
export const SAVE_DOCUMENT_REQUEST = 'SAVE_DOCUMENT_REQUEST'
export const SAVE_DOCUMENT_SUCCESS = 'SAVE_DOCUMENT_SUCCESS'
export const SAVE_DOCUMENT_FAILURE = 'SAVE_DOCUMENT_FAILURE'
export const GET_MY_DOCUMENTS_REQUEST = 'GET_MY_DOCUMENTS_REQUEST'
export const GET_MY_DOCUMENTS_SUCCESS = 'GET_MY_DOCUMENTS_SUCCESS'
export const GET_MY_DOCUMENTS_FAILURE = 'GET_MY_DOCUMENTS_FAILURE'

export const saveApplicationRequest = () => ({ type: SAVE_APPLICATION_REQUEST })
export const saveApplicationSuccess = () => ({ type: SAVE_APPLICATION_SUCCESS })
export const saveApplicationFailure = (data) => ({ type: SAVE_APPLICATION_FAILURE, payload: data })
export const getDirectionsSuccess = (data) => ({ type: GET_DIRECTIONS_SUCCESS, payload: data })
export const getDirectionsFailure = (data) => ({ type: GET_DIRECTIONS_FAILURE, payload: data })
export const saveTransactionRequest = () => ({ type: SAVE_TRANSACTION_REQUEST })
export const saveTransactionSuccess = (data) => ({ type: SAVE_TRANSACTION_SUCCESS, payload: data })
export const saveTransactionFailure = (data) => ({ type: SAVE_TRANSACTION_FAILURE, payload: data })
export const getDocumentTypesSuccess = (data) => ({ type: GET_DOCUMENT_TYPES_SUCCESS, payload: data })
export const getDocumentTypesFailure = (data) => ({ type: GET_DOCUMENT_TYPES_FAILURE, payload: data })
export const saveDocumentRequest = () => ({ type: SAVE_DOCUMENT_REQUEST })
export const saveDocumentSuccess = (data) => ({ type: SAVE_DOCUMENT_SUCCESS, payload: data })
export const saveDocumentFailure = (data) => ({ type: SAVE_DOCUMENT_FAILURE, payload: data })
export const getMyDocumentsRequest = () => ({ type: GET_MY_DOCUMENTS_REQUEST })
export const getMyDocumentsSuccess = (data) => ({ type: GET_MY_DOCUMENTS_SUCCESS, payload: data })
export const getMyDocumentsFailure = (error) => ({ type: GET_MY_DOCUMENTS_FAILURE, payload: error })

export const saveApplication = (payload) => async (dispatch) => {
  try {
    dispatch(saveApplicationRequest())
    const token = localStorage.getItem('token')
    const result = await ManageService.saveApplication(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveApplicationSuccess())
    } else {
      dispatch(saveApplicationFailure(result.error))
    }
  } catch (e) {
    dispatch(saveApplicationFailure(e.toString()))
  }
}

export const getTransactionDirections = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await ManageService.getTransactionDirections(token)
    console.log(result)
    if (result.success) {
      dispatch(getDirectionsSuccess(result.directions))
    } else {
      dispatch(getDirectionsFailure(result.error))
    }
  } catch (e) {
    dispatch(getDirectionsFailure(e.toString()))
  }
}

export const editTransaction = (transaction) => ({ type: EDIT_TRANSACTION, payload: transaction })

export const saveTransaction = (payload) => async (dispatch) => {
  try {
    dispatch(saveTransactionRequest())
    const token = localStorage.getItem('token')
    const result = await ManageService.saveTransaction(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveTransactionSuccess(result.directions))
    } else {
      dispatch(saveTransactionFailure(result.error))
    }
  } catch (e) {
    dispatch(saveTransactionFailure(e.toString()))
  }
}

export const getDocumentTypes = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await ManageService.getDocumentTypes(token)
    console.log(result)
    if (result.success) {
      dispatch(getDocumentTypesSuccess(result.types))
    } else {
      dispatch(getDocumentTypesFailure(result.error))
    }
  } catch (e) {
    dispatch(getDocumentTypesFailure(e.toString()))
  }
}

export const saveDocument = (payload) => async (dispatch) => {
  try {
    dispatch(saveDocumentRequest())
    const token = localStorage.getItem('token')
    const result = await ManageService.saveDocument(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveDocumentSuccess(result.directions))
    } else {
      dispatch(saveDocumentFailure(result.error))
    }
  } catch (e) {
    dispatch(saveDocumentFailure(e.toString()))
  }
}

export const getMyDocuments = (organizationId) => async (dispatch) => {
  try {
    dispatch(getMyDocumentsRequest())
    const token = localStorage.getItem('token')
    const result = await ManageService.getMyDocuments(organizationId, token)
    console.log(result)
    if (result.success) {
      dispatch(getMyDocumentsSuccess(result.documents))
    } else {
      dispatch(getMyDocumentsFailure(result.error))
    }
  } catch (e) {
    dispatch(getMyDocumentsFailure(e.toString()))
  }
}
