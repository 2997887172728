import * as yup from 'yup'

const applicationSchema = yup.object().shape({
    organization_name: yup.string()
        .required(),
    datetime: yup.string()
        .required(),
})

export default applicationSchema
