import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getMyOrganizationById } from '@/actions/organization'
import PageLoader from '@/components/Common/Loader/PageLoader'
import BlockDashboardInfo from '@/components/Organization/BlockDashboardInfo'
import BlockMembers from '@/components/Common/Carousel/BlockMembers'
import BlockEditMembers from '@/components/Organization/BlockEditMembers'
import BlockDashboardBudget from '@/components/Organization/BlockDashboardBudget'
import BlockDashboardProjects from '@/components/Organization/BlockDashboardProjects'
import BlockEditProjects from '@/components/Organization/BlockEditProjects'

const OrganizationDashboardPage = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loaded, setLoaded] = useState(false)

    const myOrganizationLoading = useSelector(state => state.organization.myOrganizationLoading)
    const myOrganization = useSelector(state => state.organization.myOrganization)

    useEffect(() => {
        if (id) dispatch(getMyOrganizationById(id))
    }, [id, dispatch])

    useEffect(() => {
        if (!myOrganization && !myOrganizationLoading) {
        	navigate('/404')
        } else {
            if (myOrganization) setTimeout(() => setLoaded(true), 500)
        }
    }, [myOrganizationLoading, myOrganization, navigate])

    const [editMembers, setEditMembers] = useState(false)
    const [editProjects, setEditProjects] = useState(false)

	return (
		<>
            <PageLoader loaded={loaded} />
			<BlockDashboardInfo organization={myOrganization} />
			{!editMembers 
				? <BlockMembers
					entity={myOrganization}
					position="dashboard"
					title="Zespół"
					owlItems={4}
					isShort={true}
					followButton={false}
					btnText="ZARZĄDZAJ ZESPOŁEM"
					btnAction={() => setEditMembers(true)}
				/> 
				: <BlockEditMembers
					organization={myOrganization}
					onSave={() => setEditMembers(false)}
				/>
			}
			<BlockDashboardBudget organization={myOrganization} />
			{!editProjects 
				? <BlockDashboardProjects
					organization={myOrganization}
					btnAction={() => setEditProjects(true)}
				/>
				: <BlockEditProjects
					organization={myOrganization}
					onSave={() => setEditProjects(false)}
				/>
			}
		</>
	)
}

export default OrganizationDashboardPage
