import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getInterests } from '@/actions/content'
import { saveInterests, deleteInterest } from '@/actions/user'
import ConfirmButton from './ConfirmButton'

import './styles.css'

const BlockEditInterests = ({ user, onSave }) => {
	const dispatch = useDispatch()

	const allInterests = useSelector(state => state.content.interests)

	useEffect(() => {
		dispatch(getInterests())
	}, [dispatch])

	useEffect(() => {
		if (user) {
			setUserInterests(user.interests)
		}
	}, [user])

	const [userInterests, setUserInterests] = useState([])
	const [listInterests, setListInterests] = useState([])
	const [selectedInterest, setSelectedInterest] = useState(null)
	const [selectedValue, setSelectedValue] = useState('')
	const [addedInterests, setAddedInterests] = useState([])

    const handleInputChange = (e) => {
        const { value } = e.target
        setSelectedValue(value)
        searchInterests(value)
    }

    const handleKeyDown = (e) => {
	    if (e.key === 'Enter') {
	    	addInterest()
	    }
	}

	const searchInterests = (query) => {
		const foundInterests = allInterests.filter(
			item => item.name.toLowerCase().includes(query.toLowerCase())
		).slice(0, 5)
		setListInterests(foundInterests)
	}

	const selectInterest = (id) => {
		const interest = allInterests.find(item => item.id === id)
		if (interest) {
			setSelectedInterest(interest)
			setSelectedValue(interest.name)
			setListInterests([])
		}
	}

	useEffect(() => {
		if (selectedInterest) addInterest()
	}, [selectedInterest])

	const addInterest = () => {
		if (selectedInterest) {
			setAddedInterests([ ...addedInterests, selectedInterest ])
			setSelectedInterest(null)
			setSelectedValue('')
		}
	}

	const deselectInterest = (id) => {
		const newAddedInterests = addedInterests.filter(item => item.id !== id)
		setAddedInterests(newAddedInterests)
	}

	const onSubmit = async () => {
		await dispatch(saveInterests({ interests: addedInterests }))
		onSave()
		setAddedInterests([])
	}

	const removeInterest = async (id) => {
		await dispatch(deleteInterest({ id }))
	}

	return (	
		<div className="block block-editProfileInterests" id="interests">
			<div className="block-wrap">
				<div className="block-heading blue">
					<h2>Zainteresowania</h2>
				</div>
				<div className="block-editProfileInterests-active-items">
					{userInterests.map((item, index) => {
						const { id, name } = item
						return (
							<span className="btn-tag minus" key={index}>
								{name}
								<button
									type="button"
									className="minus"
									onClick={() => removeInterest(id)}
								>-</button>
							</span>
						)
					})}
				</div>
				<div className="block-editProfile-line"></div>
				<div className="block-editProfileInterests-fields">
					<div className="form-fields">
						<div className="form-field">
							<div className="block-editProfileInterests-fields-item">
								<span className="block-editProfileInterests-fields-item_label">
									Dodaj zainteresowanie
								</span>
								<span className="block-editProfileInterests-fields-item_value">
									<span className="form-field-plus">
										<input
											type="text"
											placeholder="wpisz i zatwierdź plusikiem"
											value={selectedValue}
											onChange={handleInputChange}
											onKeyDown={handleKeyDown}
										/>
										<span className="button" onClick={addInterest}>+</span>
										{listInterests.length !== 0 && 
											<div className="block-editProfileInterests-fields-item_menu">
												{listInterests.map((item, index) => {
													const { id, name } = item
													return (
														<div
															className="block-editProfileInterests-fields-item_menu-item"
															key={index}
															onClick={() => selectInterest(id)}
														>{name}</div>
													)
												})}
											</div>
										}
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className="block-editProfileInterests-noactive-items">
					{addedInterests.map((item, index) => {
						const { id, name } = item
						return (
							<span className="btn-tag minus" key={index}>
								{name}
								<button
									type="button"
									className="minus"
									onClick={() => deselectInterest(id)}
								>-</button>
							</span>
						)
					})}
				</div>
				<ConfirmButton onSubmit={onSubmit} />
			</div>
		</div>
	)
}

export default BlockEditInterests
