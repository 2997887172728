import './styles.css'
import loader from '@/assets/images/loader.gif'

const PageLoader = ({ loaded }) => {
	return (
		<div className={`page-loader page-loader${loaded ? '-loaded' : ''}`}>
			<img src={loader} alt="" />
		</div>
	)
}

export default PageLoader
