import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import { getCarouselPosition } from '@/utils/content.utils'
import OfferItem from './OfferItem'

import './styles.css'

const OffersWidget = ({ widget }) => {
	return (
		<div className="block-edit-offers-widget">
			<div className="block-edit-offers-widget-title">{widget.title}</div>
			<div className="block-edit-offers-widget-body">
				<div className="block-edit-offers-widget-body-item">
					<div className="block-edit-offers-widget-body-item-caption">{widget.items[0].caption}</div>
					<div className="block-edit-offers-widget-body-item-value">{widget.items[0].value}</div>					
				</div>
				<div className="block-edit-offers-widget-body-delimiter"></div>
				<div className="block-edit-offers-widget-body-item">
					<div className="block-edit-offers-widget-body-item-caption">{widget.items[1].caption}</div>
					<div className="block-edit-offers-widget-body-item-value">{widget.items[1].value}</div>					
				</div>
			</div>			
		</div>
	)
}

const BlockEditOffers = ({ company, onSave = () => {} }) => {
	const [offers, setOffers] = useState([])

	const [activeOffersCount, setActiveOffersCount] = useState(0)
	const [inactiveOffersCount, setInactiveOffersCount] = useState(0)

	useEffect(() => {
		if (company) {
			setOffers(company.offers)

			setActiveOffersCount(company.offers.filter(offer => offer.active).length)
			setInactiveOffersCount(company.offers.filter(offer => !offer.active).length)
		}
	}, [company])

	const responsive = {
        0: {
            margin: 3,
            items: 1
        },
        768: {
            margin: 10,
            items: 2
        },
        1450: {
            margin: 46,
            items: 2
        }
    }

	const widgets = [
		{
			title: 'Podsumowanie ofert',
			items: [
				{
					caption: <>łączna ilość ofert<br />pracy i stażu</>,
					value: offers.length
				},
				{
					caption: <>oferty aktywne<br />/ oferty nieaktywne</>,
					value: `${activeOffersCount} / ${inactiveOffersCount}`
				}
			]
		},
		{
			title: 'Oferty sponsorowane',
			items: [
				{
					caption: <>Rachunek za<br />zamieszczone oferty -<br />okres ostatnie 30 dni</>,
					value: '0 netto'
				},
				{
					caption: <>łącznie z wersjami<br />roboczymi</>,
					value: '0 netto'
				}
			]
		}
	]

	if (!company) return null

	return (
		<div className="block block-offers">
			<div className="block-wrap">
				<div className="block-heading red">
					<h2>Oferty pracy</h2>
				</div>
				<div className="block-edit-offers-widgets">
					{widgets.map((widget, index) => <OffersWidget widget={widget} key={index} />)}
				</div>
				<div className="block-edit-offers-subtitle">
					Twoje oferty ({ offers.length })
				</div>
				<div className="block-offers-items">
					<OwlCarousel
						className='owl-offers owl-carousel'
						loop
						nav
						dots={true}
						responsive={responsive}
					>
						{offers.map((offer, index) => 
							<OfferItem company={company} offer={offer} mode="edit" key={index} />
						)}
					</OwlCarousel>
				</div>
				<div
					className="block-offers-button"
					style={{ display: 'flex', justifyContent: 'center', gridGap: '14px' }}
				>
					<Link
						to={`/offers/company/${company.id}`}
						className="btn btn-primary"
					>
						ZARZĄDZAJ OFERTAMI
					</Link>
					<Link
						to={`/offers/company/${company.id}/create`}
						className="btn btn-primary"
					>
						DODAJ OFERTĘ
					</Link>
				</div>
			</div>
		</div>
	)
}

export default BlockEditOffers
