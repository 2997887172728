import { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import { getCurrentDate, reverseDateFormat } from '@/utils/datetime.utils'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'

import './styles.css'

const ProjectsWidget = ({ widget }) => {
	return (
		<div className="block-projects-widget">
			<div className="block-projects-widget-title">{widget.title}</div>
			<div className="block-projects-widget-body">
				{widget.items.map((item, index) => {
					const { caption, value } = item
					const isLastItem = index === widget.items.length - 1
					return (
						<Fragment key={index}>
							<div className="block-projects-widget-body-item">
								<div className="block-projects-widget-body-item-caption">{item.caption}</div>
								<div className="block-projects-widget-body-item-value">{item.value}</div>					
							</div>
							{!isLastItem && <div className="block-projects-widget-body-delimiter"></div>}
						</Fragment>
					)
				})}
			</div>			
		</div>
	)
}

const ProjectItem = ({ project, area }) => {
	const [isExpanded, setIsExpanded] = useState(false)

	const getImage = (logo_image) => {
		if (logo_image) {
			return `${STORAGE_URL}/${logo_image}` 
		}
		return userDefault
	}

	const toggleExpand = () => {
		setIsExpanded(!isExpanded)
	}

	return (
		<div className="block-projects-item">
			<div className="block-projects-item_image">
				<span><img src={getImage(project.logo_image)} width="" height="" alt="" /></span>
				<div className="block-projects-item_area">
					<p>obszar projektu: <br /><strong>{area}</strong></p>
				</div>
			</div>
			<div className="block-projects-item_info">
				<h3 className="block-projects-item_title">{project.name}</h3>
				<div className="block-projects-item_content">
					<p className={`block-projects-item_description ${isExpanded ? 'expanded' : 'collapsed'}`}>
						{project.description}
						
					</p>
					{!isExpanded && <a onClick={toggleExpand} style={{ cursor: 'pointer' }}>czytaj dalej</a>}
					{isExpanded && <a onClick={toggleExpand} style={{ cursor: 'pointer' }}>zwiń</a>}
				</div>
				<p className="block-projects-item_date">
					{reverseDateFormat(project.date_from)} - {reverseDateFormat(project.date_to)}
				</p>
				<p className="block-projects-item_caption blue">
					Tekst o tym co chcemy podkreślić - hasłowo jedna makimum 2 linijki
				</p>
				<div className="block-projects-item_button">
					<Link
						to={`/project/edit/${project.id}`}
						className="btn btn-sm btn-secondary"
					>EDYTUJ PROJEKT</Link>
				</div>
			</div>
		</div>
	)
}

const BlockEditProjects = ({ organization, onSave }) => {
	const [projects, setProjects] = useState([])
	const [data, setData] = useState({ area: '' })

	const [activeProjectsCount, setActiveProjectsCount] = useState(0)
	const [inactiveProjectsCount, setInactiveProjectsCount] = useState(0)

	useEffect(() => {
		if (organization) {
			setProjects(organization.projects)
			setData({ area: organization.activity_type })

			const currentDate = getCurrentDate()
			const uncompleted = organization.projects.filter(
				item => item.date_to > currentDate
			).length
			setActiveProjectsCount(organization.projects.length - uncompleted)
			setInactiveProjectsCount(uncompleted)
		}
	}, [organization])

	const responsive = {
        0: {
            margin: 3,
            items: 1
        },
        992: {
            margin: 10,
            items: 2
        },
        1450: {
            margin: 46,
            items: 2
        }
    }

	const widgets = [
		{
			title: 'Podsumowanie projektów',
			items: [
				{
					caption: <>łączna ilość projektów</>,
					value: projects.length
				},
				{
					caption: <>projekty aktywne</>,
					value: activeProjectsCount
				},
				{
					caption: <>projekty w trakcie<br />tworzenia</>,
					value: inactiveProjectsCount
				},
				{
					caption: <>ilość zaangażowanych<br />osób łącznie</>,
					value: 0
				}
			]
		}
	]

	return (
		<div className="block block-projects">
			<div className="block-wrap">
				<div className="block-heading red">
					<h2>Projekty organizacji</h2>
				</div>
				<div className="block-projects-widgets">
					{widgets.map((widget, index) => <ProjectsWidget widget={widget} key={index} />)}
				</div>
				<div className="block-projects-subtitle">
					Twoje projekty ({ projects.length })
				</div>
				<div className="block-projects-items">
					<OwlCarousel
						className='owl-projects owl-carousel'
						loop
						nav
						dots={true}
						responsive={responsive}
					>
						{projects.map((project, index) => 
							<ProjectItem project={project} area={data.area} key={index} />
						)}
					</OwlCarousel>
				</div>
				<div className="block-projects-button">
					<a className="btn btn-primary">DODAJ PROJEKT</a>
				</div>
			</div>
		</div>
	)
}

export default BlockEditProjects
