import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login } from '@/actions/auth'
import LoginForm from '@/components/Auth/Form/LoginForm'

const LoginPage = ({ texts }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const onSubmit = async (payload) => {
		const success = await dispatch(login(payload))
		// if (success) navigate('/user/dashboard')
		if (success) window.location.href = '/user/dashboard'
	}

	return (
		<div className="loginForm-section">
			<div className="container">
				<LoginForm texts={texts} onSubmit={onSubmit} />
			</div>
		</div>
	)
}

export default LoginPage
