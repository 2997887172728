import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const MyCompanyPage = () => {
	const navigate = useNavigate()

    const myCompanies = useSelector(state => state.company.myCompanies)

    useEffect(() => {
    	if (myCompanies.length) {
    		navigate(`/company/dashboard/${myCompanies[0].id}`)
    	}
    }, [myCompanies])

    return null
}

export default MyCompanyPage
