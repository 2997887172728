import { useState, useEffect } from 'react'
import { getActionItem } from '@/constants/manage'
import closeRed from '@/assets/images/close-red.svg'
import closeWhite from '@/assets/images/close-white.svg'

const ManageDialog = ({ organization, dialog, onClose }) => {
    const [data, setData] = useState({
        icon: null,
        title: '',
        dark: false
    })

    const [FormComponent, setFormComponent] = useState(null)

    useEffect(() => {
        if (dialog.action) {
            const actionItem = getActionItem(dialog.action)
            if (actionItem) {
                const { icon, dialogTitle: title, dark, form: Form, formBtnText } = actionItem
                setData({ icon, title, dark })
                setFormComponent(Form 
                    ? <Form
                        organization={organization}
                        btnText={formBtnText}
                        onCancel={onClose}
                    /> 
                    : null
                )
            }
        }
    }, [dialog])

    if (!dialog.open) return null

    return (
        <div className="manage-dialog-wrap">
            <div className="manage-dialog">
                <div className={`manage-dialog-head ${data.dark ? 'dark' : ''}`}>
                    <div className="manage-dialog-title">
                        <img src={data.icon} alt="" />
                        <span>{data.title}</span>
                    </div>
                    <div className="manage-dialog-close" onClick={onClose}>
                        <img src={data.dark ? closeWhite : closeRed} alt="" />
                    </div>
                </div>
                <div className="manage-dialog-body">
                    {FormComponent}
                </div>
            </div>
        </div>
    )
}

export default ManageDialog
