export const emptyFilters = { 
	all: { city: '' },
	user: { city: '', area: '', specialization: '' },
	organization: { city: '', activity_type: '' },
	company: { city: '', activity_type: '' },
	project: { city: '' },
}

export const resultTypes = {
	all: 'wszystko',
	user: 'osoby',
	organization: 'organizacje projektowe',
	company: 'firmy',
	project: 'projekty',
}
