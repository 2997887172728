import { useState, useEffect } from 'react'
import { emptyFilters, resultTypes } from '@/constants/search'
import { ucfirst } from '@/utils/content.utils'
import Select from 'react-select'
import arrow from '@/assets/images/search-arrow-down.svg'

import './styles.css'

const SearchFields = ({
	cities,
	areas,
	specializations,
	studies,
	workplaces,
	activityTypes,
	organizations,
	onSearch
}) => {
	const [query, setQuery] = useState('')
	const [filters, setFilters] = useState({ type: '', ...emptyFilters.all })

	const selects = {
		city: { type: 'select', name: 'city', label: 'miejscowość' },
		area: { type: 'select', name: 'area', label: 'obszar rozwoju' },
		specialization: { type: 'select', name: 'specialization', label: 'specjalizacja' },
		studies: { type: 'select', name: 'studies', label: 'uczelnia' },
		workplace: { type: 'select', name: 'workplace', label: 'miejsce pracy' },
		activity_type: { type: 'select', name: 'activity_type', label: 'rodzaj działalności' },
		organization: { type: 'select', name: 'organization', label: 'organizacja' },
	}

	const [optionsMap, setOptionsMap] = useState({
		city: [],
		area: [],
		specialization: [],
		studies: [],
		workplace: [],
		activityType: [],
		organization: [],
	})

	const inputs = {
		members_count: { name: 'members_count', label: 'wielkość' },
		projects_count: { name: 'projects_count', label: 'ilość projektów' }
	}

	useEffect(() => {
		setOptionsMap((prev) => ({
			...prev,
			city: Object.values(cities).map(item => ({ value: item, label: item })),
		}))
	}, [cities])

	useEffect(() => {
		setOptionsMap((prev) => ({
			...prev,
			area: Object.values(areas).map(item => ({ value: item, label: item })),
		}))
	}, [areas])

	useEffect(() => {
		setOptionsMap((prev) => ({
			...prev,
			specialization: Object.values(specializations).map(item => ({ value: item, label: item })),
		}))
	}, [specializations])

	useEffect(() => {
		setOptionsMap((prev) => ({
			...prev,
			studies: Object.values(studies).map(item => ({ value: item, label: item })),
		}))
	}, [studies])

	useEffect(() => {
		setOptionsMap((prev) => ({
			...prev,
			workplace: Object.values(workplaces).map(item => ({ value: item, label: item })),
		}))
	}, [workplaces])

	useEffect(() => {
		setOptionsMap((prev) => ({
			...prev,
			activity_type: Object.values(activityTypes).map(item => ({ value: item, label: item })),
		}))
	}, [activityTypes])

	useEffect(() => {
		setOptionsMap((prev) => ({
			...prev,
			organization: organizations.map(item => ({ value: item.id, label: item.name })),
		}))
	}, [organizations])

	const params = {
		all: [
			selects.city,
		],
		user: [
			selects.city,
			selects.area,
			selects.specialization,
			selects.studies,
			selects.workplace,
		],
		organization: [
			selects.city,
			selects.activity_type,
			selects.studies,
		],
		company: [
			selects.city,
			selects.activity_type,
		],
		project: [
			selects.city,
			selects.organization,
		],
	}

	const [fields, setFields] = useState(params.all)

	const handleTypeChange = (option) => {
		const { value: type } = option
		setFields(params[type])
		setFilters({ type, ...emptyFilters[type] })
	}

	const handleInputChange = (e) => {
		const { value } = e.target
		onSearch({ query: value, filters })
		setQuery(value)
	}

	const handleSelectChange = (option, name) => {
		const { value } = option
		setFilters(prevFilters => ({ ...prevFilters, [name]: value }))
	}

	const handleSearch = () => {
		onSearch({ query, filters })
	}

	const clearFilters = () => {
		onSearch({ query, filters: { type: '', ...emptyFilters.all } })
		setFilters({ type: '', ...emptyFilters.all })
	}

	return (
		<>
			<div className="block-search-fields">
				<div className="block-search-field">
					<input
						type="search"
						placeholder="wpisz poszukiwany wyraz"
						name="search"
						value={query}
						onChange={handleInputChange}
					/>
				</div>
				<div className="block-search-field block-search-field-25">
					<div className="select-field">
						<Select
							className="search-select"
							name="type"
							placeholder="Co szukać"
							value={
								Object.entries(resultTypes)
									  .map(([value, label]) => ({ value, label }))
									  .find(opt => opt.value === filters.type) 
								|| 
								({ value: 'all', label: 'wszystko' })
							}
							options={Object.entries(resultTypes).map(([value, label]) => ({ value, label }))}
							onChange={(option) => handleTypeChange(option)}
						/>
					</div>
				</div>
				{fields.map((field, index) => {
					const { type, name, label } = field
					const options = optionsMap[name] || []
					return (
						<div className="block-search-field block-search-field-25" key={index}>
							<div className="select-field">
								{type === 'select' && <Select
									className="search-select"
									name={name}
									placeholder={label}
									value={options.find(opt => opt.value === filters[name]) || null}
									options={options}
									onChange={(option) => handleSelectChange(option, name)}
								/>}
							</div>
						</div>
					)
				})}
			</div>
			<div className="block-search-buttons">
				<div className="block-search-button">
					<button className="btn btn-primary" onClick={handleSearch}>ZASTOSUJ FILTRY</button>
				</div>
				<div className="block-search-button">
					<button className="btn btn-outline-primary" onClick={clearFilters}>WYCZYŚĆ FILTRY</button>
				</div>
			</div>
		</>
	)
}

export default SearchFields
