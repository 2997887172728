import { Link, useNavigate } from 'react-router-dom'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import OfferItems from './OfferItems'

import './styles.css'

const OffersWidget = ({ widget }) => {
	return (
		<div className="block-offers-widget">
			<div className="block-offers-widget-title">{widget.title}</div>
			<div className="block-offers-widget-body">
				<div className="block-offers-widget-body-item">
					<div className="block-offers-widget-body-item-caption">{widget.items[0].caption}</div>
					<div className="block-offers-widget-body-item-value">{widget.items[0].value}</div>					
				</div>
				<div className="block-offers-widget-body-delimiter"></div>
				<div className="block-offers-widget-body-item">
					<div className="block-offers-widget-body-item-caption">{widget.items[1].caption}</div>
					<div className="block-offers-widget-body-item-value">{widget.items[1].value}</div>					
				</div>
			</div>			
		</div>
	)
}

const CompanyOffers = ({ company, offers, loaded }) => {
	const navigate = useNavigate()

	const getImage = (image) => {
		if (image) {
			return `${STORAGE_URL}/${image}` 
		}
		return userDefault
	}

	const activeOffersCount = offers.filter(offer => offer.active).length
	const inactiveOffersCount = offers.filter(offer => !offer.active).length

	const widgets = [
		{
			title: 'Podsumowanie ofert',
			items: [
				{ caption: <>łączna ilość ofert pracy<br />i stażu</>, value: offers.length },
				{
					caption: <>oferty aktywne<br />/ oferty nieaktywne</>,
					value: `${activeOffersCount} / ${inactiveOffersCount}`
				}
			]
		},
		{
			title: 'Strefa finansów',
			items: [
				{
					caption: <>Rachunek za zamieszczone<br />oferty - okres ostatnie 30 dni</>,
					value: '1200 netto'
				},
				{ caption: <>łącznie z wersjami<br />roboczymi</>, value: '1200 netto' }
			]
		}
	]

    const goBack = () => {
        navigate(-1)
    }

	return (
		<div className="block block-search block-offers-search">
			<div className="block-close">
				<a style={{ cursor: 'pointer' }} onClick={goBack}><span></span></a>
			</div>
			<div className="block-wrap">
				<div className="block-offers-company-head">
					<div className="block-offers-company-logo">
						<img src={getImage(company.logo_image)} alt="" />
					</div>
					<div className="block-search-heading">
						<h1>Panel ofert {company.name}</h1>
						<h2>Zarządzaj ofertami pracy i stażu</h2>
					</div>
				</div>
				<div className="block-offers-company-widgets">
					{widgets.map((widget, index) => <OffersWidget widget={widget} key={index} />)}
				</div>
				<div className="block-offers-company-add">
					<Link
						to={`/offers/company/${company.id}/create`}
						className="btn btn-primary"
					>
						<span>DODAJ OFERTĘ</span>
					</Link>
				</div>
				<OfferItems offers={offers} loaded={loaded} position="company" />
			</div>
		</div>
	)
}

export default CompanyOffers
