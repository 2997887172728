import ProjectService from '@/services/project.service'
import { saveSuccess, saveError } from '@/utils/notify.utils'

export const GET_VIEW_PROJECT_REQUEST = 'GET_VIEW_PROJECT_REQUEST'
export const GET_VIEW_PROJECT_SUCCESS = 'GET_VIEW_PROJECT_SUCCESS'
export const GET_VIEW_PROJECT_FAILURE = 'GET_VIEW_PROJECT_FAILURE'
export const GET_MY_PROJECT_REQUEST = 'GET_MY_PROJECT_REQUEST'
export const GET_MY_PROJECT_SUCCESS = 'GET_MY_PROJECT_SUCCESS'
export const GET_MY_PROJECT_FAILURE = 'GET_MY_PROJECT_FAILURE'
export const SAVE_PROJECT_SUCCESS = 'SAVE_PROJECT_SUCCESS'
export const SAVE_PROJECT_FAILURE = 'SAVE_PROJECT_FAILURE'

export const getViewProjectRequest = () => ({ type: GET_VIEW_PROJECT_REQUEST })
export const getViewProjectSuccess = (data) => ({ type: GET_VIEW_PROJECT_SUCCESS, payload: data })
export const getViewProjectFailure = (error) => ({ type: GET_VIEW_PROJECT_FAILURE, payload: error })
export const getMyProjectRequest = () => ({ type: GET_MY_PROJECT_REQUEST })
export const getMyProjectSuccess = (data) => ({ type: GET_MY_PROJECT_SUCCESS, payload: data })
export const getMyProjectFailure = (error) => ({ type: GET_MY_PROJECT_FAILURE, payload: error })
export const saveProjectSuccess = () => ({ type: SAVE_PROJECT_SUCCESS })
export const saveProjectFailure = (error) => ({ type: SAVE_PROJECT_FAILURE, payload: error })

export const getProjectById = (id) => async (dispatch) => {
  try {
    dispatch(getViewProjectRequest())
    const token = localStorage.getItem('token')
    const result = await ProjectService.getProjectById(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getViewProjectSuccess(result.project))
    } else {
      dispatch(getViewProjectFailure(result.error))
    }
  } catch (e) {
    dispatch(getViewProjectFailure(e.toString()))
  }
}

export const getMyProjectById = (id) => async (dispatch) => {
  try {
    dispatch(getMyProjectRequest())
    const token = localStorage.getItem('token')
    const result = await ProjectService.getMyProjectById(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getMyProjectSuccess(result.project))
    } else {
      dispatch(getMyProjectFailure(result.error))
    }
  } catch (e) {
    dispatch(getMyProjectFailure(e.toString()))
  }
}

export const uploadImage = (payload, project_id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await ProjectService.uploadImage(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProjectSuccess())
      dispatch(getMyProjectById(project_id))
      saveSuccess()
      return result.data
    } else {
      dispatch(saveProjectFailure(result.error))
      saveError(result.error)
      return null
    }
  } catch (e) {
    dispatch(saveProjectFailure(e.toString()))
    saveError(e.toString())
    return null
  }
}

export const saveInfo = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await ProjectService.saveInfo(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProjectSuccess())
      dispatch(getMyProjectById(payload.project_id))
      saveSuccess()
    } else {
      dispatch(saveProjectFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProjectFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveAbout = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await ProjectService.saveAbout(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProjectSuccess())
      dispatch(getMyProjectById(payload.project_id))
      saveSuccess()
    } else {
      dispatch(saveProjectFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProjectFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveGoals = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await ProjectService.saveGoals(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProjectSuccess())
      dispatch(getMyProjectById(payload.project_id))
      saveSuccess()
    } else {
      dispatch(saveProjectFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProjectFailure(e.toString()))
    saveError(e.toString())
  }
}

export const savePartner = (payload, project_id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await ProjectService.savePartner(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProjectSuccess())
      dispatch(getMyProjectById(project_id))
      saveSuccess()
    } else {
      dispatch(saveProjectFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProjectFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveProject = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await ProjectService.saveProject(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProjectSuccess())
      dispatch(getMyProjectById(payload.project_id))
      saveSuccess()
    } else {
      dispatch(saveProjectFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProjectFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveMembers = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await ProjectService.saveMembers(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProjectSuccess())
      dispatch(getMyProjectById(payload.project_id))
      saveSuccess()
    } else {
      dispatch(saveProjectFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProjectFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveStage = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await ProjectService.saveStage(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProjectSuccess())
      dispatch(getMyProjectById(payload.project_id))
      saveSuccess()
    } else {
      dispatch(saveProjectFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProjectFailure(e.toString()))
    saveError(e.toString())
  }
}
