import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateOfferParam } from '@/actions/offer'
import { getMyCompanyOffers } from '@/actions/company'
import { Link } from 'react-router-dom'
import DataLoader from '@/components/Common/Loader/DataLoader'
import { offerTypes, workSystems, contractTypes } from '@/constants/offer'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'

import promo from '@/assets/images/offers/promo.svg'
import edit from '@/assets/images/offers/edit.svg'
import settings from '@/assets/images/offers/settings.svg'
import active from '@/assets/images/offers/active.svg'

import computers from '@/assets/images/offers/computers.svg'
import location from '@/assets/images/offers/location.svg'
import target from '@/assets/images/offers/target.svg'
import bag from '@/assets/images/offers/bag.svg'
import money from '@/assets/images/offers/money.svg'

import './styles.css'

const OfferItem = ({ offer, position, onActiveChanged }) => {
	const dispatch = useDispatch()

	const [params, setParams] = useState({
		active: false,
		promoted: false
	})
	const [shadowAvailable, setShadowAvailable] = useState(false)

	useEffect(() => {
		if (offer) {
			const { active, promoted } = offer
			setParams({ active, promoted })
		}
	}, [offer])

	useEffect(() => {
		if (position === 'company') {
			setShadowAvailable(true)
		}
	}, [position])

	const getImage = (logo_image) => {
		if (logo_image) {
			return `${STORAGE_URL}/${logo_image}` 
		}
		return userDefault
	}

	const actions = [
		{
			icon: promo,
			title: 'NIE PROMOWANA',
			highlightedTitle: 'OFERTA PROMOWANA',
			highlighted: params.promoted,
			link: null,
			param: 'promoted'
		},
		{
			icon: edit,
			title: 'EDYTUJ TREŚĆ',
			highlighted: true,
			link: `/offers/company/${offer.company.id}/edit/${offer.id}`,
			param: null
		},
		{
			icon: settings,
			title: 'EDYTUJ USTAWIENIA',
			highlighted: true,
			link: null,
			param: null
		},
		{
			icon: active,
			title: 'OFERTA NIEAKTYWNA',
			highlightedTitle: 'OFERTA AKTYWNA',
			highlighted: params.active,
			link: null,
			param: 'active'
		}
	]

	const changeParam = async (param) => {
		const value = !params[param]
		setParams({ ...params, [param]: value })
		await dispatch(updateOfferParam({ offer_id: offer.id, param, value }))
		if (param === 'active' && position === 'company') {
			await dispatch(getMyCompanyOffers(offer.company.id))
			await onActiveChanged(offer.id, value)
		}
	}

	return (
		<div className="block-offer-item">
			{!!params.promoted && <div className="block-offer-item-promoted">
				<div>oferta promowana</div>
			</div>}
			<div className="block-offer-item-logo">
				<img src={getImage(offer.company.logo_image)} width="" height="" alt="" />
			</div>
			<div className="block-offer-item-info">
				<div className="block-offer-item-info-top">
					<div className="block-offer-item-info-title">
						<div className="block-offer-item-info-title-offer">{offer.title}</div>
						<div className="block-offer-item-info-title-company">{offer.company.name}</div>
					</div>
					<div className="block-offer-item-info-options">
						<div className="block-offer-item-info-options-types">
							{Object.entries(offerTypes).map(([key, name]) => (
								<span
									className={`${name === offer.offer_type ? 'active' : ''}`}
									key={key}
								>{name}</span>
							))}
						</div>
						{position === 'search' && 
							<div className="header-icon star block-offer-item-info-options-favorite"><a></a></div>
						}
						<Link to={`/offer/${offer.id}/view`} className="block-search-resultsItem__view"></Link>
					</div>					
				</div>
				<div className="block-offer-item-info-bottom">
					<div className="block-offer-item-info-features">
						<div className="block-offer-item-info-features-item">
							<img src={computers} alt="" />
							<span>{workSystems[offer.work_system]}</span>
						</div>
						<div className="block-offer-item-info-features-item">
							<img src={location} alt="" />
							<span>{offer.city}</span>
						</div>
						<div className="block-offer-item-info-features-item">
							<img src={target} alt="" />
							{offer.specializations.length !== 0 && <span>{offer.specializations[0]}</span>}
						</div>
						<div className="block-offer-item-info-features-item">
							<img src={bag} alt="" />
							<span>{contractTypes[offer.contract_type]}</span>
						</div>
						<div className="block-offer-item-info-features-item">
							<img src={money} alt="" />
							<span>{offer.salary_from} – {offer.salary_to} netto B2B</span>
						</div>
					</div>
				</div>
			</div>
			{shadowAvailable && <div className="block-offer-item-shadow">
				<div className="block-offer-item-actions">
					{actions.map((action, index) => {
						const { icon, title, highlightedTitle, highlighted, link, param } = action
						return link ? (
							<Link
								to={link}
								className={`block-offer-item-action ${highlighted ? 'highlighted' : ''}`}
								key={index}
							>
								<img src={icon} alt="" />
								<span>{title}</span>
							</Link>
						) : (
							<div
								className={`block-offer-item-action ${highlighted ? 'highlighted' : ''}`}
								key={index}
								onClick={() => changeParam(param)}
							>
								<img src={icon} alt="" />
								<span>{highlightedTitle && highlighted ? highlightedTitle : title}</span>
							</div>
						)
					})}
				</div>
			</div>}
		</div>
	)
}

const OfferItems = ({ offers, loaded, position }) => {
	const [viewOffers, setViewOffers] = useState(offers)

	useEffect(() => {
		if (offers) setViewOffers(offers)
	}, [offers])

	const [activeSwitch, setActiveSwitch] = useState({
		search: 'all',
		company: 'active'
	})

	const showAllSearchOffers = () => {
		setViewOffers(offers)
		setActiveSwitch({ ...activeSwitch, search: 'all' })
	}

	const showPromotedSearchOffers = () => {
		setViewOffers(offers.filter(offer => offer.promoted))
		setActiveSwitch({ ...activeSwitch, search: 'promoted' })
	}

	const showActiveCompanyOffers = () => {
		setViewOffers(offers.filter(offer => offer.active))
		setActiveSwitch({ ...activeSwitch, company: 'active' })
	}

	const showInactiveCompanyOffers = () => {
		setViewOffers(offers.filter(offer => !offer.active))
		setActiveSwitch({ ...activeSwitch, company: 'inactive' })
	}

	const switches = {
		search: [
			{
				title: 'wszystkie',
				active: activeSwitch.search === 'all',
				action: showAllSearchOffers
			},
			{
				title: 'tylko promowane',
				active: activeSwitch.search === 'promoted',
				action: showPromotedSearchOffers
			},
			{ title: 'obserwowane', active: false, action: null }
		],
		company: [
			{
				title: 'Oferty aktywne',
				active: activeSwitch.company === 'active',
				action: showActiveCompanyOffers
			},
			{
				title: 'Oferty robocze',
				active: activeSwitch.company === 'inactive',
				action: showInactiveCompanyOffers
			}
		]
	}

	const switchAction = (type, index) => {
		const action = switches[type][index].action
		if (action) action()
	}

	const onActiveChanged = (id, active) => {
		const changedOffers = offers.map(offer => offer.id === id ? ({ ...offer, active }) : offer)
		if (activeSwitch.company === 'active') {
			setViewOffers(changedOffers.filter(offer => offer.active))
		} else if (activeSwitch.company === 'inactive') {
			setViewOffers(changedOffers.filter(offer => !offer.active))
		}
	}

	return (
		<div className="block-offer-items" style={{ position: 'relative' }}>
            <DataLoader loaded={loaded} />
			<div className="block-offer-items-head block-offer-items-switch">
				{switches[position].map((item, index) => {
					const { title, active } = item
					return (
						<span
							className={`block-offer-items-switch-item ${active ? 'active' : ''}`}
							key={index}
							onClick={() => switchAction(position, index)}
						>{title}</span>
					)
				})}
			</div>
			<div className="block-offer-items-list">
				{viewOffers.map((offer, index) => 
					<OfferItem
						offer={offer}
						position={position}
						key={index}
						onActiveChanged={onActiveChanged}
					/>
				)}
			</div>
		</div>
	)
}

export default OfferItems
