import UserService from '@/services/user.service'
import { getUser } from './auth'
import { saveSuccess, saveError } from '@/utils/notify.utils'

export const SET_ACCOUNT_TYPE_SUCCESS = 'SET_ACCOUNT_TYPE_SUCCESS'
export const SET_ACCOUNT_TYPE_FAILURE = 'SET_ACCOUNT_TYPE_FAILURE'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE'
export const SAVE_PROFILE_SUCCESS = 'SAVE_PROFILE_SUCCESS'
export const SAVE_PROFILE_FAILURE = 'SAVE_PROFILE_FAILURE'
export const CONFIRM_SKILL_SUCCESS = 'CONFIRM_SKILL_SUCCESS'
export const CONFIRM_SKILL_FAILURE = 'CONFIRM_SKILL_FAILURE'
export const GET_VIEW_USER_REQUEST = 'GET_VIEW_USER_REQUEST'
export const GET_VIEW_USER_SUCCESS = 'GET_VIEW_USER_SUCCESS'
export const GET_VIEW_USER_FAILURE = 'GET_VIEW_USER_FAILURE'
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'
export const SEARCH_USERS_REQUEST = 'SEARCH_USERS_REQUEST'
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS'
export const SEARCH_USERS_FAILURE = 'SEARCH_USERS_FAILURE'

export const setAccountTypeSuccess = () => ({ type: SET_ACCOUNT_TYPE_SUCCESS })
export const setAccountTypeFailure = (error) => ({ type: SET_ACCOUNT_TYPE_FAILURE, payload: error })
export const uploadImageSuccess = () => ({ type: UPLOAD_IMAGE_SUCCESS })
export const uploadImageFailure = (error) => ({ type: UPLOAD_IMAGE_FAILURE, payload: error })
export const saveProfileSuccess = () => ({ type: SAVE_PROFILE_SUCCESS })
export const saveProfileFailure = (error) => ({ type: SAVE_PROFILE_FAILURE, payload: error })
export const confirmSkillSuccess = () => ({ type: CONFIRM_SKILL_SUCCESS })
export const confirmSkillFailure = (error) => ({ type: CONFIRM_SKILL_FAILURE, payload: error })
export const getViewUserRequest = () => ({ type: GET_VIEW_USER_REQUEST })
export const getViewUserSuccess = (data) => ({ type: GET_VIEW_USER_SUCCESS, payload: data })
export const getViewUserFailure = (error) => ({ type: GET_VIEW_USER_FAILURE, payload: error })
export const getUsersRequest = () => ({ type: GET_USERS_REQUEST })
export const getUsersSuccess = (data) => ({ type: GET_USERS_SUCCESS, payload: data })
export const getUsersFailure = (error) => ({ type: GET_USERS_FAILURE, payload: error })
export const searchUsersRequest = () => ({ type: SEARCH_USERS_REQUEST })
export const searchUsersSuccess = (data) => ({ type: SEARCH_USERS_SUCCESS, payload: data })
export const searchUsersFailure = (error) => ({ type: SEARCH_USERS_FAILURE, payload: error })

export const setAccountType = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await UserService.setAccountType(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(setAccountTypeSuccess())
      dispatch(getUser())
      return true
    } else {
      dispatch(setAccountTypeFailure(result.message))
      return false
    }
  } catch (e) {
    dispatch(setAccountTypeFailure(e.toString()))
    return false
  }
}

export const uploadImage = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await UserService.uploadImage(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(uploadImageSuccess())
      dispatch(getUser())
      saveSuccess()
      return result.data
    } else {
      dispatch(uploadImageFailure(result.error))
      saveError(result.error)
      return null
    }
  } catch (e) {
    dispatch(uploadImageFailure(e.toString()))
    saveError(e.toString())
    return null
  }
}

export const saveProfile = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await UserService.saveProfile(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProfileSuccess())
      dispatch(getUser())
      saveSuccess()
    } else {
      dispatch(saveProfileFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProfileFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveAbout = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await UserService.saveAbout(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProfileSuccess())
      dispatch(getUser())
      saveSuccess()
    } else {
      dispatch(saveProfileFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProfileFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveInterests = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await UserService.saveInterests(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProfileSuccess())
      dispatch(getUser())
      saveSuccess()
    } else {
      dispatch(saveProfileFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProfileFailure(e.toString()))
    saveError(e.toString())
  }
}

export const deleteInterest = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await UserService.deleteInterest(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProfileSuccess())
      dispatch(getUser())
      saveSuccess()
    } else {
      dispatch(saveProfileFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProfileFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveWorks = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await UserService.saveWorks(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProfileSuccess())
      dispatch(getUser())
      saveSuccess()
    } else {
      dispatch(saveProfileFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProfileFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveProjects = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await UserService.saveProjects(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProfileSuccess())
      dispatch(getUser())
      saveSuccess()
    } else {
      dispatch(saveProfileFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProfileFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveAchievements = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await UserService.saveAchievements(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProfileSuccess())
      dispatch(getUser())
      saveSuccess()
    } else {
      dispatch(saveProfileFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProfileFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveSkills = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await UserService.saveSkills(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProfileSuccess())
      dispatch(getUser())
      saveSuccess()
    } else {
      dispatch(saveProfileFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProfileFailure(e.toString()))
    saveError(e.toString())
  }
}

export const deleteSkill = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await UserService.deleteSkill(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveProfileSuccess())
      dispatch(getUser())
      saveSuccess()
    } else {
      dispatch(saveProfileFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveProfileFailure(e.toString()))
    saveError(e.toString())
  }
}

export const confirmSkill = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await UserService.confirmSkill(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(confirmSkillSuccess())
      dispatch(getUserById(payload.user_id))
      saveSuccess()
    } else {
      dispatch(confirmSkillFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(confirmSkillFailure(e.toString()))
    saveError(e.toString())
  }
}

export const getUserById = (id) => async (dispatch) => {
  try {
    dispatch(getViewUserRequest())
    const token = localStorage.getItem('token')
    const result = await UserService.getUserById(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getViewUserSuccess(result.user))
    } else {
      dispatch(getViewUserFailure(result.message))
    }
  } catch (e) {
    dispatch(getViewUserFailure(e.toString()))
  }
}

export const getUsers = () => async (dispatch) => {
  try {
    dispatch(getUsersRequest())
    const token = localStorage.getItem('token')
    const result = await UserService.getUsers(token)
    console.log(result)
    if (result.success) {
      dispatch(getUsersSuccess(result.users))
    } else {
      dispatch(getUsersFailure(result.message))
    }
  } catch (e) {
    dispatch(getUsersFailure(e.toString()))
  }
}

export const searchUsers = (payload) => async (dispatch) => {
  try {
    dispatch(searchUsersRequest())
    const token = localStorage.getItem('token')
    const result = await UserService.searchUsers(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(searchUsersSuccess(result.users))
    } else {
      dispatch(searchUsersFailure(result.message))
    }
  } catch (e) {
    dispatch(searchUsersFailure(e.toString()))
  }
}
