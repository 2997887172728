import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getMyCompanyById } from '@/actions/company'
import PageLoader from '@/components/Common/Loader/PageLoader'
import BlockDashboardInfo from '@/components/Company/BlockDashboardInfo'
import BlockMembers from '@/components/Common/Carousel/BlockMembers'
import BlockEditMembers from '@/components/Company/BlockEditMembers'
import BlockOffers from '@/components/Company/BlockOffers'
import BlockEditOffers from '@/components/Company/BlockEditOffers'

const CompanyDashboardPage = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loaded, setLoaded] = useState(false)

    const myCompanyLoading = useSelector(state => state.company.myCompanyLoading)
    const myCompany = useSelector(state => state.company.myCompany)

    useEffect(() => {
        if (id) dispatch(getMyCompanyById(id))
    }, [id, dispatch])

    useEffect(() => {
        if (!myCompany && !myCompanyLoading) {
        	navigate('/404')
        } else {
            if (myCompany) setTimeout(() => setLoaded(true), 500)
        }
    }, [myCompanyLoading, myCompany, navigate])

    const [editMembers, setEditMembers] = useState(false)
    const [editOffers, setEditOffers] = useState(false)

	return (
		<>
            <PageLoader loaded={loaded} />
			<BlockDashboardInfo company={myCompany} />
			{!editMembers 
				? <BlockMembers
					entity={myCompany}
					position="dashboard"
					title="Zaangażowane osoby"
					owlItems={4}
					isShort={true}
					followButton={false}
					btnText="ZARZĄDZAJ ZESPOŁEM"
					btnAction={() => setEditMembers(true)}
				/> 
				: <BlockEditMembers
					company={myCompany}
					onSave={() => setEditMembers(false)}
				/>
			}
			<BlockOffers company={myCompany} position="dashboard" />
		</>
	)
}

export default CompanyDashboardPage
