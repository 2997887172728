import * as yup from 'yup'
import { workSystems, contractTypes, minSalary, maxSalary } from '@/constants/offer'

export const infoSchema = yup.object().shape({
  name: yup.string()
    .required(),
  website: yup.string()
    .required(),
  description: yup.string()
    .required()
    .max(280),
  activity_type: yup.string()
    .required(),
  specialization: yup.string()
    .required(),
})

export const aboutSchema = yup.object().shape({
  about: yup.string()
    .required()
    .max(2000),
})
