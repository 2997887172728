import {
    SAVE_APPLICATION_REQUEST,
    SAVE_APPLICATION_SUCCESS,
    SAVE_APPLICATION_FAILURE,
    GET_DIRECTIONS_SUCCESS,
    GET_DIRECTIONS_FAILURE,
    EDIT_TRANSACTION,
    SAVE_TRANSACTION_REQUEST,
    SAVE_TRANSACTION_SUCCESS,
    SAVE_TRANSACTION_FAILURE,
    GET_DOCUMENT_TYPES_SUCCESS,
    GET_DOCUMENT_TYPES_FAILURE,
    SAVE_DOCUMENT_REQUEST,
    SAVE_DOCUMENT_SUCCESS,
    SAVE_DOCUMENT_FAILURE,
    GET_MY_DOCUMENTS_REQUEST,
    GET_MY_DOCUMENTS_SUCCESS,
    GET_MY_DOCUMENTS_FAILURE
  } from '../actions/manage'
  
  const initialState = {
    directions: null,
    transaction: null,
    documentTypes: [],
    myDocuments: [],
    error: null,
  }
  
  const manageReducer = (state = initialState, action) => {
    switch (action.type) {
      case SAVE_APPLICATION_REQUEST:
        return {
          ...state,
          error: null
        }
      case SAVE_APPLICATION_SUCCESS:
        return {
          ...state,
          error: null
        }
      case SAVE_APPLICATION_FAILURE:
        return {
          ...state,
          error: action.payload
        }
      case GET_DIRECTIONS_SUCCESS:
        return {
          ...state,
          directions: action.payload,
          error: null
        }
      case GET_DIRECTIONS_FAILURE:
        return {
          ...state,
          directions: null,
          error: action.payload
        }
      case EDIT_TRANSACTION:
        return {
          ...state,
          transaction: action.payload
        }
      case SAVE_TRANSACTION_REQUEST:
        return {
          ...state,
          error: null
        }
      case SAVE_TRANSACTION_SUCCESS:
        return {
          ...state,
          error: null
        }
      case SAVE_TRANSACTION_FAILURE:
        return {
          ...state,
          error: action.payload
        }
      case GET_DOCUMENT_TYPES_SUCCESS:
        return {
          ...state,
          documentTypes: action.payload,
          error: null
        }
      case GET_DOCUMENT_TYPES_FAILURE:
        return {
          ...state,
          documentTypes: [],
          error: action.payload
        }
      case SAVE_DOCUMENT_REQUEST:
        return {
          ...state,
          error: null
        }
      case SAVE_DOCUMENT_SUCCESS:
        return {
          ...state,
          error: null
        }
      case SAVE_DOCUMENT_FAILURE:
        return {
          ...state,
          error: action.payload
        }
      case GET_MY_DOCUMENTS_REQUEST:
        return {
          ...state
        }
      case GET_MY_DOCUMENTS_SUCCESS:
        return {
          ...state,
          myDocuments: action.payload,
          error: null
        }
      case GET_MY_DOCUMENTS_FAILURE:
        return {
          ...state,
          myDocuments: null,
          error: action.payload
        }
      default:
        return state
    }
  }
  
  export default manageReducer
  