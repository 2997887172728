import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDialog } from '@/actions/chat'
import { useParams, useNavigate } from 'react-router-dom'
import ChatDialog from './ChatDialog'
import ChatUsers from './ChatUsers'

import './styles.css'

const ChatBlock = () => {
    const { id } = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const dialog = useSelector(state => state.chat.dialog)

	const [selectedDialog, setSelectedDialog] = useState(null)

	useEffect(() => {
		if (id) {
			dispatch(getDialog(id))
		}
	}, [id])

	useEffect(() => {
		if (dialog) {
			setSelectedDialog(dialog)
		}
	}, [dialog])

    const onSelect = (dialogId) => {
    	navigate(`/chat/${dialogId}`)
    }

    const onClose = () => {
    	setSelectedDialog(null)
    	navigate(`/chat`)
    }

    const onMessageRead = () => {
    	const unread = selectedDialog.unread
    	setSelectedDialog(prevDialog => ({ ...prevDialog, unread: Math.max(unread - 1, 0) }))
    }

	return (
		<div className="block block-chat">
			<div className="block-wrap">
				<ChatDialog dialog={selectedDialog} onClose={onClose} onMessageRead={onMessageRead} />
				<ChatUsers selectedDialog={selectedDialog} onSelect={onSelect} />
			</div>
		</div>
	)
}

export default ChatBlock
