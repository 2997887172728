import action1 from '@/assets/images/manage-action-1.svg'
import action2 from '@/assets/images/manage-action-2.svg'
import action3 from '@/assets/images/manage-action-3.svg'
import ApplicationForm from '@/components/Manage/Form/ApplicationForm'
import TransactionForm from '@/components/Manage/Form/TransactionForm'
import DocumentForm from '@/components/Manage/Form/DocumentForm'

export const actions = [
    {
        icon: action1,
        text: 'Program inkubacji i akceleracji projektów',
        btnText: 'DOŁĄCZ',
        dialogTitle: 'Wniosek o dołączenie do programu Akceleracji i Inkubacji',
        dark: false,
        action: 'join_application',
        form: ApplicationForm,
        formBtnText: 'WYŚLIJ'
    },
    {
        icon: action2,
        text: 'Faktury i inne dokumenty finansowe',
        btnText: 'WYSTAW',
        dialogTitle: 'Dodaj fakturę / dokument finansowy',
        dark: false,
        action: 'load_document',
        form: DocumentForm,
        formBtnText: 'WYŚLIJ'
    },
    {
        icon: action3,
        text: 'Przelewy',
        btnText: 'WYŚLIJ PRZELEW',
        dialogTitle: 'Wyślij przelew',
        dark: true,
        action: 'edit_transaction',
        form: TransactionForm,
        formBtnText: 'WYŚLIJ'
    },
]

export const getActionItem = (action) => {
    return actions.find(item => item.action === action)
}

export const universities = [
    "Uniwersytet Warszawski",
    "Politechnika Warszawska",
    "Uniwersytet Jagielloński",
    "Uniwersytet im. Adama Mickiewicza w Poznaniu",
    "Uniwersytet Wrocławski",
    "Politechnika Wrocławska",
    "Uniwersytet Łódzki",
    "Politechnika Łódzka",
    "Uniwersytet Gdański",
    "Politechnika Gdańska",
    "Uniwersytet Marii Curie-Skłodowskiej w Lublinie",
    "Politechnika Lubelska",
    "Uniwersytet Mikołaja Kopernika w Toruniu",
    "Politechnika Białostocka",
    "Uniwersytet Śląski w Katowicach",
    "Politechnika Śląska",
    "Uniwersytet Ekonomiczny w Krakowie",
    "Uniwersytet Ekonomiczny we Wrocławiu",
    "Uniwersytet Ekonomiczny w Poznaniu",
    "Uniwersytet Ekonomiczny w Katowicach",
    "Uniwersytet Medyczny w Warszawie",
    "Uniwersytet Medyczny im. Piastów Śląskich we Wrocławiu",
    "Akademia Górniczo-Hutnicza w Krakowie",
    "Szkoła Główna Handlowa w Warszawie",
    "Uniwersytet Szczeciński",
    "Politechnika Świętokrzyska",
    "Akademia Sztuk Pięknych w Warszawie",
    "Akademia Sztuk Pięknych w Krakowie",
    "Uniwersytet Przyrodniczy w Lublinie",
    "Uniwersytet Przyrodniczy w Poznaniu",
    "Uniwersytet Przyrodniczy we Wrocławiu",
    "Uniwersytet Kazimierza Wielkiego w Bydgoszczy",
    "Uniwersytet Rzeszowski",
    "Uniwersytet Zielonogórski",
    "Akademia Wychowania Fizycznego w Warszawie",
    "Akademia Wychowania Fizycznego im. Jerzego Kukuczki w Katowicach",
    "Akademia Wychowania Fizycznego we Wrocławiu",
    "Akademia Wychowania Fizycznego im. Bronisława Czecha w Krakowie",
    "Akademia Wychowania Fizycznego w Poznaniu",
    "Akademia Marynarki Wojennej w Gdyni",
    "Wyższa Szkoła Bankowa w Gdańsku",
    "Wyższa Szkoła Bankowa we Wrocławiu",
    "Wyższa Szkoła Informatyki i Zarządzania w Rzeszowie",
    "Wyższa Szkoła Zarządzania i Bankowości w Krakowie",
    "Polsko-Japońska Akademia Technik Komputerowych w Warszawie"
]

export const currencies = [
    { label: 'PLN', value: 'pln' },
    { label: 'EUR', value: 'eur' },
    { label: 'USD', value: 'usd' },
]
