import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getMyProjectById } from '@/actions/project'
import PageLoader from '@/components/Common/Loader/PageLoader'
import BlockEditInfo from '@/components/Project/BlockEditInfo'
import BlockEditAbout from '@/components/Project/BlockEditAbout'
import BlockEditGoals from '@/components/Project/BlockEditGoals'
import BlockEditSupport from '@/components/Project/BlockEditSupport'
import BlockEditMembers from '@/components/Project/BlockEditMembers'
import BlockEditStages from '@/components/Project/BlockEditStages'

const ProjectEditPage = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loaded, setLoaded] = useState(false)

    const myProjectLoading = useSelector(state => state.project.myProjectLoading)
    const myProject = useSelector(state => state.project.myProject)

    useEffect(() => {
        if (id) dispatch(getMyProjectById(id))
    }, [id, dispatch])

    useEffect(() => {
        if (!myProject && !myProjectLoading) {
        	navigate('/404')
        } else {
            if (myProject) setTimeout(() => setLoaded(true), 500)
        }
    }, [myProjectLoading, myProject, navigate])

	return (
		<>
            <PageLoader loaded={loaded} />
			<BlockEditInfo project={myProject} />
            <BlockEditAbout project={myProject} />
            <BlockEditGoals project={myProject} />
            <BlockEditSupport project={myProject} />
            <BlockEditMembers project={myProject} />
            <BlockEditStages project={myProject} />
		</>
	)
}

export default ProjectEditPage
