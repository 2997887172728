import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { saveAbout } from '@/actions/company'
import TextEditor from '@/components/Common/Edit/TextEditor'
import ConfirmButton from '@/components/Common/Edit/ConfirmButton'

const BlockEditAbout = ({ company }) => {
	const dispatch = useDispatch()

	const [data, setData] = useState({ about: '' })

	useEffect(() => {
		if (company) {
			setData({ about: company.about })
		}
	}, [company])

	const handleContentChange = (content) => {
		setData({ about: content })
	}

	const onSubmit = async () => {
		await dispatch(saveAbout({ ...data, company_id: company.id }))
	}

	return (
		<form className="block block-editProfileAbout" id="about">
			<div className="block-wrap">
				<div className="block-heading yellow">
					<h2>O firmie</h2>
				</div>
				<div className="form-fields">
					<div className="form-field">
						<TextEditor content={data.about} onContentChange={handleContentChange} />
					</div>
				</div>
				<ConfirmButton onSubmit={onSubmit} />
			</div>
		</form>
	)
}

export default BlockEditAbout
