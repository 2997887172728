const InfoPage = ({ title, content }) => {
	return (
		<div className="container">
			<h1>{title}</h1>
			<div dangerouslySetInnerHTML={{ __html: content }}></div>
		</div>
	)
}

export default InfoPage
