import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { saveProject, saveStage } from '@/actions/project'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import DateEditor from '@/components/Common/Edit/DateEditor'
import { reverseDateFormat, formatDate } from '@/utils/datetime.utils'
import ellipse from '@/assets/images/ellipse.svg'
import { generateRandomString } from '@/utils/content.utils'
import ConfirmButton from '@/components/Common/Edit/ConfirmButton'

const StageItem = ({ stage, index, onEdit }) => {
	const handleClick = () => {
		onEdit(stage, index)
	}

	return (		
		<div className="block-timeline-item">
			<div className="block-timeline-item__top">
				<div className="block-timeline-item__top-inner">
					<span></span>
					{stage.date_to && <p>{reverseDateFormat(stage.date_to)}</p>}
					{!stage.date_to && <p>nieokreślona</p>}
				</div>
			</div>
			<div className="block-timeline-item__wrap" onClick={handleClick}>
				<p className="block-timeline-item__title">{stage.title}</p>
				<p className="block-timeline-item__date">{reverseDateFormat(stage.date_from)}</p>
				<div className="block-timeline-item__content">
					<p>{stage.description}</p>
				</div>
			</div>
		</div>
	)
}

const BlockEditStages = ({ project }) => {
	const dispatch = useDispatch()

	const [stages, setStages] = useState([])
	const [dates, setDates] = useState({ from: null, to: null })
	const [stage, setStage] = useState({
    	key: null,
		title: '',
		description: '',
		date_from: null,
		date_to: null
	})
	const [activeIndex, setActiveIndex] = useState(0)

	const [errors, setErrors] = useState({
		from: false,
		to: false
	})

	useEffect(() => {
		if (project) {
			setStages(project.stages.sort(sortStages))
			setDates({
				from: project.date_from || null,
				to: project.date_to || null
			})
		}
	}, [project])

	const sortStages = (a, b) => {
		if (a.date_from === b.date_from) return 0
		return a.date_from < b.date_from ? -1 : 1
	}

	const responsive = {
        0: {
            margin: 3,
            items: 1
        },
        768: {
            margin: 10,
            items: 3
        },
        992: {
            margin: 16,
            items: 3
        },
        1450: {
            margin: 28,
            items: 3
        }
    }

    const handleDateChange = ({ name, value }) => {
        setDates({ ...dates, [name]: value })
    }

    const editStage = (stage, index) => {
    	const { key, title, description, date_from, date_to } = stage
    	setStage({ key, title, description, date_from, date_to })
    	setActiveIndex(index)
    }

    const addStage = () => {
    	setStage({
    		key: null,
			title: '',
			description: '',
			date_from: null,
			date_to: null
		})
    }

    const handleInputChange = ({ name, value }) => {
        setStage({ ...stage, [name]: value })
    }

    const onSubmit = () => {
    	dispatch(saveProject({
    		date_from: dates.from,
    		date_to: dates.to,
    		project_id: project.id
    	}))
    	const { key, title, description, date_from, date_to } = stage
    	if (title && description && date_from && date_to) {
    		dispatch(saveStage({
    			key: key || generateRandomString(),
    			title,
    			description,
    			date_from: formatDate(date_from),
    			date_to: formatDate(date_to),
    			project_id: project.id
    		}))
    	}
    }

	return (
		<div className="block block-timeline">
			<div className="block-wrap">
				<div className="block-heading yellow">
					<h2>timeline projektu</h2>
				</div>
				<div>
					<div className="block-editStages_params">
						<div className="form-field">
							<span>start projektu:</span>
							<DateEditor
								wrapperClassName={errors.from ? 'form-field-error' : ''}
								innerHtml={errors.from ? <span className="form-field-icon">!</span> : null}
								name="from"
								placeholder="DD/MM/RRRR"
								selected={dates.from}
								allowFuture={true}
								onChange={handleDateChange}
							/>
						</div>
						<div className="form-field">
							<span>koniec projektu:</span>
							<DateEditor
								wrapperClassName={errors.to ? 'form-field-error' : ''}
								innerHtml={errors.to ? <span className="form-field-icon">!</span> : null}
								name="to"
								placeholder="DD/MM/RRRR"
								selected={dates.to}
								allowFuture={true}
								onChange={handleDateChange}
							/>
						</div>
					</div>
					<div className="block-timeline-items">
						<span className="block-timeline-line"></span>
						<OwlCarousel
							className='owl-timeline owl-carousel'
							nav
							dots={false}
						    center={true}
							responsive={responsive}
							startPosition={activeIndex}
						>
							{stages.map((stage, index) => 
								<StageItem stage={stage} index={index} onEdit={editStage} key={index} />
							)}
						</OwlCarousel>
					</div>
					<div className="block-editStages_add" onClick={addStage}>
						<img src={ellipse} alt="" />
						<span>dodaj wydarzenie / ważną datę / milestone</span>
					</div>
					<div className="block-editStages_form">
						<div className="form-field">
							<span>data początku</span>
							<DateEditor
								wrapperClassName={errors.date_from ? 'form-field-error' : ''}
								innerHtml={errors.date_from ? <span className="form-field-icon">!</span> : null}
								name="date_from"
								placeholder="DD/MM/RRRR"
								selected={stage.date_from}
								allowFuture={true}
								onChange={handleInputChange}
							/>
						</div>
						<div className="form-field">
							<span>data zakończenia</span>
							<DateEditor
								wrapperClassName={errors.date_to ? 'form-field-error' : ''}
								innerHtml={errors.date_to ? <span className="form-field-icon">!</span> : null}
								name="date_to"
								placeholder="DD/MM/RRRR"
								selected={stage.date_to}
								allowFuture={true}
								onChange={handleInputChange}
							/>
						</div>
						<div className="form-field">
							<span>tytuł wydarzenia</span>
							<input
								type="text"
								name="title"
								placeholder="wpisz tytuł"
								value={stage.title}
								onChange={(e) => handleInputChange(e.target)}
							/>
						</div>
						<div className="form-field">
							<span>krótki opis</span>
							<textarea
								name="description"
								placeholder="wpisz opis MAKS 200 znaków"
								value={stage.description}
								onChange={(e) => handleInputChange(e.target)}
							/>
						</div>
					</div>
				</div>
				<ConfirmButton onSubmit={onSubmit} />
			</div>
		</div>
	)
}

export default BlockEditStages
