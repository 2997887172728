import api from './api'

class ManageService
{
	async saveApplication(payload, token) {
		try {
			const response = await api.post(`/manage/application/save`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ManageService][saveApplication][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getTransactionDirections(token) {
		try {
			const response = await api.get(`/manage/transaction/directions`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ManageService][getTransactionDirections][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveTransaction(payload, token) {
		try {
			const response = await api.post(`/manage/transaction/save`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ManageService][saveTransaction][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getDocumentTypes(token) {
		try {
			const response = await api.get(`/manage/document/types`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ManageService][getDocumentTypes][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveDocument(payload, token) {
		try {
			const response = await api.post(`/manage/document/save`, payload, {
	            headers: { 
	            	Authorization: `Bearer ${token}`,
	            	'Content-Type': 'multipart/form-data'
	            }
	        })
			return response.data
		} catch (e) {
			console.log('[ManageService][saveDocument][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getMyDocuments(organizationId, token) {
		try {
			const response = await api.get(`/manage/document/${organizationId}/get`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ManageService][getMyDocuments][error]', e)
			return { success: false, message: e.toString() }
		}
	}
}

export default new ManageService()
