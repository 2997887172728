import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCities, getActivityTypes, searchOffers } from '@/actions/offer'
import { useNavigate } from 'react-router-dom'
import { emptyFilters } from '@/constants/offer'
import SearchFields from './SearchFields'
import OfferItems from './OfferItems'

const OffersSearch = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const cities = useSelector(state => state.offer.cities)
	const activityTypes = useSelector(state => state.offer.activityTypes)
	const offers = useSelector(state => state.offer.offers)

	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			await dispatch(getCities())
			await dispatch(getActivityTypes())
			await dispatch(searchOffers({ query: '', filters: emptyFilters }))
			setTimeout(() => setLoaded(true), 500)
		}

		fetchData()
	}, [dispatch])

	const onSearch = async (payload) => {
		setTimeout(() => setLoaded(false), 500)
		await dispatch(searchOffers(payload))
		setTimeout(() => setLoaded(true), 500)
	}

    const goBack = () => {
        navigate(-1)
    }

	return (
		<div className="block block-search block-offers-search">
			<div className="block-close">
				<a style={{ cursor: 'pointer' }} onClick={goBack}><span></span></a>
			</div>
			<div className="block-wrap">
				<div className="block-search-heading">
					<h1>Oferty pracy i stażu</h1>
					<h2>Wpisz to czego szukasz lub wybierz z listy</h2>
				</div>
				<SearchFields cities={cities} activityTypes={activityTypes} onSearch={onSearch} />
				<OfferItems offers={offers} loaded={loaded} position="search" />
			</div>
		</div>
	)
}

export default OffersSearch
