import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { saveGoals } from '@/actions/project'
import { generateRandomString } from '@/utils/content.utils'
import circleCheck from '@/assets/images/circle-check-wave.svg'
import ConfirmButton from '@/components/Common/Edit/ConfirmButton'

import './styles.css'

const BlockEditGoals = ({ project }) => {
	const dispatch = useDispatch()

	const initialData = { key: null, content: '' }

	const [goals, setGoals] = useState(Array(10).fill(initialData))

	useEffect(() => {
		if (project) {
			const projectGoals = project.goals.map((goal) => ({
				key: goal.key || generateRandomString(),
				content: goal.content || ''
			}))

			setGoals([
				...projectGoals,
				...Array(10 - projectGoals.length).fill(initialData)
			])
		}
	}, [project/*, initialData*/])

	const handleInputChange = (index, value) => {
		const newGoals = [...goals]
	    const goal = { ...newGoals[index] }
	    if (!goal.key) goal.key = generateRandomString()
	    goal.content = value
	    newGoals[index] = goal
	    setGoals(newGoals)
	}

	const onSubmit = async () => {
		await dispatch(saveGoals({
			goals: goals.filter(item => item.key && item.content),
			project_id: project.id
		}))
	}

	return (
		<form className="block block-editProfileAchievements" id="goals">
			<div className="block-wrap">
				<div className="block-heading blue">
					<h2>cele projektu</h2>
				</div>
				<p className="block-editProfileAchievements-description">
					Wpisz do 5 najważniejszych celi
				</p>
				<div className="block-editProfileAchievements-fields">
					<div className="form-fields">
						{goals.map((goal, index) => {
							const { content } = goal
							return (								
								<div className="form-field" key={index}>
									<div className="block-editProfileAchievements-fields-item">
										<span className="block-editProfileAchievements-fields-item_label">
											<img src={circleCheck} width="" height="" alt="" />
										</span>
										<span className="block-editProfileAchievements-fields-item_value">
											<input
												type="text"
												placeholder="wpisz osiągnięcie"
												value={content}
												onChange={(e) => handleInputChange(index, e.target.value)}
											/>
										</span>
									</div>
								</div>
							)
						})}
					</div>
				</div>
				<ConfirmButton onSubmit={onSubmit} />
			</div>
		</form>
	)
}

export default BlockEditGoals
