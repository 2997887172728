import blockPlus from '@/assets/images/block-plus.png'

const BlockNoItems = ({ title = 'upsssss....', text = null }) => {
	return (
		<div className="block-service-noitems">
			<div className="block-service-noitems-left">
				<div className="block-service-noitems-title">{title}</div>
				{text && <div className="block-service-noitems-text">{text}</div>}								
			</div>
			<div className="block-service-noitems-right">
				<img src={blockPlus} alt="" />
			</div>
		</div>
	)
}

export default BlockNoItems
