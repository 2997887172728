import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveDocument } from '@/actions/manage'
import { getMyOrganizationById } from '@/actions/organization'
import documentSchema from '@/validations/document.schema'
import action2 from '@/assets/images/manage-action-2.svg'
import Select from 'react-select'
import Buttons from './Buttons'

const MainFields = ({ params, onChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onChange({ name, value })
    }

    const handleSelectChange = (name, value) => {
        onChange({ name, value })
    }

    return (
        <>
            {params.map((param, index) => {
                const { field, name, label, mask, placeholder, value, options, error } = param
                let addClassName = ''
                if (field === 'select') {
                    addClassName += 'block-search-field'
                }
                return (
                    <div
                        className={`manage-dialog-form-field ${name} ${addClassName} ${error ? ' form-field-error' : ''}`}
                        key={index}
                    >
                        <label  htmlFor={name}>{label}</label>
                        {field === 'input-text' && (
                            <input
                                id={name}
                                name={name}
                                type="text"
                                placeholder={placeholder}
                                value={value}
                                onChange={handleInputChange}
                            />
                        )}
                        {field === 'select' && (
					        <div className="select-field">
                                <Select
                                    className="search-select"
                                    id={name}
                                    name={name}
                                    placeholder={placeholder}
                                    value={value}
                                    options={options}
                                    onChange={
                                        (option) => handleSelectChange(name, option.value)
                                    }
                                />
                            </div>
                        )}
						{error && <span className="form-field-icon">!</span>}
                    </div>
                )
            })}
        </>
    )
}

const UploadFiles = ({ files, onFileChange, onFileRemove }) => {
    const handleFileUpload = (e, index) => {
        const file = e.target.files[0]
        if (file) {
            onFileChange(file, index)
        }
    }

    return (
        <div className="manage-dialog-form-field manage-dialog-form-files">
            <label>Dokumenty</label>
            <div className="manage-dialog-form-files-grid">
                {Array.from({ length: 4 }).map((_, index) => (
                    <div key={index} className="manage-dialog-form-files-item">
                        {files[index] ? (
                            <div className="file-item">
                                <span>{files[index].name}</span>
                                <button
                                    className="btn btn-thirdy"
                                    onClick={() => onFileRemove(index)}
                                >Usuń</button>
                            </div>
                        ) : (
                            <label className="file-upload">
                                <img src={action2} alt="" />
                                <span>DODAJ DOKUMENT</span>
                                <input
                                    type="file"
                                    onChange={(e) => handleFileUpload(e, index)}
                                    style={{ display: 'none' }}
                                />
                            </label>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

const DocumentForm = ({ organization, btnText, onCancel }) => {
    const dispatch = useDispatch()

    const documentTypes = useSelector(state => state.manage.documentTypes)

    const [data, setData] = useState({
        id: null,
        name: '',
        type_id: null,
        contractor: ''
    })

	const [errors, setErrors] = useState({
		name: false,
        type_id: false,
		contractor: false
	})

    const [files, setFiles] = useState([null, null, null, null])

    const [documentTypeOptions, setDocumentTypeOptions] = useState([])

    useEffect(() => {
        if (documentTypes) {
            setDocumentTypeOptions(documentTypes.map(
                type => ({ label: type.name, value: type.id })
            ))
        }
    }, [documentTypes])

    const mainParams = [
        {
            field: 'input-text',
            name: 'name',
            label: 'Nazwa faktury',
            placeholder: 'wpisz',
            value: data.name,
            error: errors.name
        },
        {
            field: 'select',
            name: 'type_id',
            label: 'Typ dokumentu',
            placeholder: 'wybierz typ z listy',
            value: documentTypeOptions.find(
                item => item.value === data.type_id
            ),
            options: documentTypeOptions,
            error: errors.type_id
        },
        {
            field: 'input-text',
            name: 'contractor',
            label: 'Kontrachent',
            placeholder: 'wpisz nazwę',
            value: data.contractor,
            error: errors.contractor
        },
    ]

    const onChange = ({ name, value }) => {
        setData({ ...data, [name]: value })
        setErrors({ ...errors, [name]: false })
    }

    const onFileChange = (file, index) => {
        const newFiles = [...files]
        newFiles[index] = file
        setFiles(newFiles)
    }

    const onFileRemove = (index) => {
        const newFiles = [...files]
        newFiles[index] = null
        setFiles(newFiles)
    }

	const setError = (name) => {
		setErrors({ ...errors, [name]: true })
		return false
	}

	const validateForm = async (formData) => {
		try {
			await documentSchema.validate(formData, { abortEarly: false })
			return true
		} catch (errors) {
			// console.error(errors.inner)
			let error = errors.inner[0]
			return setError(error.params.path)
		}
	}

    const onSubmit = async () => {
	    const formValid = await validateForm(data)
        const hasFiles = files.some(file => file !== null)
        if (formValid && hasFiles) {
            const formData = new FormData()
            formData.append('organization_id', organization.id)
            formData.append('name', data.name)
            formData.append('type_id', data.type_id)
            formData.append('contractor', data.contractor)
            files.forEach((file, index) => {
                if (file) formData.append(`files[${index}]`, file)
            })
            await dispatch(saveDocument(formData))
            await dispatch(getMyOrganizationById(organization.id))
            onCancel()
        }
    }

    return (
        <div className="manage-dialog-form document-form">
            <MainFields params={mainParams} onChange={onChange} />
            <UploadFiles files={files} onFileChange={onFileChange} onFileRemove={onFileRemove} />
            <Buttons btnText={btnText} onCancel={onCancel} onSubmit={onSubmit}/>
        </div>
    )
}

export default DocumentForm
