import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import { getCarouselPosition } from '@/utils/content.utils'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import icon1 from '@/assets/images/offers/icon-1.svg'
import icon2 from '@/assets/images/offers/icon-2.svg'
import OfferItem from './OfferItem'
import BlockNoItems from '@/components/Common/Service/BlockNoItems'

const BlockOffers = ({ company, position }) => {
	const [offers, setOffers] = useState([])
	const headingRef = useRef(null)

	useEffect(() => {
		if (company) {
			setOffers(company.offers)
		}
	}, [company])

	const responsive = {
        0: {
            margin: 3,
            items: 1
        },
        768: {
            margin: 10,
            items: 2
        },
        1450: {
            margin: 46,
            items: 2
        }
    }

	const handleChanged = (e) => {
		if (e.item) {
	        const { from, to, total } = getCarouselPosition(e.item)

			if (headingRef.current) {
				headingRef.current.textContent = `oferty ${from} i ${to} z ${total}`
			}
		}
	}

	if (!offers.length) {
		return (
			<div className="block block-offers">
				<div className="block-wrap">
					<div className="block-heading red" style={{ marginBottom: 0 }}>
						<h2>Oferty pracy</h2>
					</div>
					{position === 'profile' && (
						<div
							className="block-content block-content-overflow block-lh-2"
						>(brak danych)</div>
					)}
					{position === 'dashboard' && company && (<>
						<BlockNoItems text={<>
							Nie masz dodanej żadnej oferty. Klikni w buton poniżej by dodać ofertyu.
						</>} />
						<div className="block-offers-button">
							<Link
								to={`/offers/company/${company.id}`}
								className="btn btn-primary"
							>
								ZARZĄDZAJ OFERTAMI
							</Link>
						</div>
					</>)}
				</div>
			</div>
		)
	}

	return (
		<div className="block block-offers">
			<div className="block-wrap">
				<div className="block-heading red">
					<h2>Oferty pracy <span ref={headingRef} className="block-heading-other-sm"></span></h2>
				</div>
				<div className="block-offers-items">
					<OwlCarousel
						className='owl-offers owl-carousel'
						loop
						nav
						dots={true}
						responsive={responsive}
						onChanged={handleChanged}
					>
						{offers.map((offer, index) => 
							<OfferItem company={company} offer={offer} position={position} key={index} />
						)}
					</OwlCarousel>
				</div>
				<div className="block-offers-button">
					{position == 'profile' && 
						<a className="btn btn-primary">zobacz wszystkie oferty</a>
					}
					{position == 'dashboard' && 
						<Link
							to={`/offers/company/${company.id}`}
							className="btn btn-primary"
						>
							ZARZĄDZAJ OFERTAMI
						</Link>
					}
				</div>
			</div>
		</div>
	)
}

export default BlockOffers
