import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveTransaction } from '@/actions/manage'
import { getMyOrganizationById } from '@/actions/organization'
import { currencies } from '@/constants/manage'
import transactionSchema from '@/validations/transaction.schema'
import { formatDate } from '@/utils/datetime.utils'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import DateEditor from '@/components/Common/Edit/DateEditor'
import Buttons from './Buttons'

const MainFields = ({ params, onChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onChange({ name, value })
    }

    const handleSelectChange = (name, value) => {
        onChange({ name, value })
    }

    return (
        <>
            {params.map((param, index) => {
                const { field, name, label, mask, placeholder, value, options, error } = param
                let addClassName = ''
                if (field === 'select') {
                    addClassName += 'block-search-field'
                }
                return (
                    <div
                        className={`manage-dialog-form-field ${name} ${addClassName} ${error ? ' form-field-error' : ''}`}
                        key={index}
                    >
                        <label  htmlFor={name}>{label}</label>
                        {field === 'input-text' && (
                            <input
                                id={name}
                                name={name}
                                type="text"
                                placeholder={placeholder}
                                value={value}
                                onChange={handleInputChange}
                            />
                        )}
                        {field === 'input-mask' && (
                            <InputMask
                                id={name}
                                name={name}
                                type="text"
                                mask={mask}
                                placeholder={placeholder}
                                value={value}
                                onChange={handleInputChange}
                            />
                        )}
                        {field === 'select' && (
					        <div className="select-field">
                                <Select
                                    className="search-select"
                                    id={name}
                                    name={name}
                                    placeholder={placeholder}
                                    value={value}
                                    options={options}
                                    onChange={
                                        (option) => handleSelectChange(name, option.value)
                                    }
                                />
                            </div>
                        )}
						{error && <span className="form-field-icon">!</span>}
                    </div>
                )
            })}
        </>
    )
}

const AddressFields = ({ params, onChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onChange({ name, value })
    }

    return (
        <div className="manage-dialog-form-field manage-dialog-form-address">
            <label>Adres</label>
            <div className="manage-dialog-form-field-grid">
                {params.map((param, index) => {
                    const { field, name, mask, placeholder, value, size, error } = param
                    return (
                        <div
                            className={`manage-dialog-form-field ${name} ${size} ${error ? ' form-field-error' : ''}`}
                            key={index}
                        >
                            {field === 'input-text' && (
                                <input
                                    id={name}
                                    name={name}
                                    type="text"
                                    placeholder={placeholder}
                                    value={value}
                                    onChange={handleInputChange}
                                />
                            )}
                            {field === 'input-mask' && (
                                <InputMask
                                    id={name}
                                    name={name}
                                    type="text"
                                    mask={mask}
                                    placeholder={placeholder}
                                    value={value}
                                    onChange={handleInputChange}
                                />
                            )}
						    {error && <span className="form-field-icon">!</span>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const AmountFields = ({ params, onChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onChange({ name, value })
    }

    const handleSelectChange = (name, value) => {
        onChange({ name, value })
    }

    return (
        <div className="manage-dialog-form-field manage-dialog-form-amount">
            <label>Kwota przelewu</label>
            <div className="manage-dialog-form-field-grid">
                {params.map((param, index) => {
                    const { field, name, label, placeholder, value, options, size, error } = param
                    let addClassName = ''
                    if (field === 'select') {
                        addClassName += 'block-search-field'
                    }
                    return (
                        <div
                            className={`manage-dialog-form-field ${name} ${size} ${addClassName} ${error ? ' form-field-error' : ''}`}
                            key={index}
                            >
                            {label && <label  htmlFor={name}>{label}</label>}
                            {field === 'input-number' && (
                                <input
                                    id={name}
                                    name={name}
                                    type="number"
                                    placeholder={placeholder}
                                    value={value}
                                    onChange={handleInputChange}
                                />
                            )}
                            {field === 'select' && (
                                <div className="select-field">
                                    <Select
                                        className="search-select"
                                        id={name}
                                        name={name}
                                        placeholder={placeholder}
                                        value={value}
                                        options={options}
                                        onChange={
                                            (option) => handleSelectChange(name, option.value)
                                        }
                                    />
                                </div>
                            )}
						    {error && <span className="form-field-icon">!</span>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const DocumentField = ({ param, onChange }) => {
    const { name, placeholder, value, options, error } = param
    console.log('options', options)

    const handleSelectChange = (name, value) => {
        onChange({ name, value })
    }

    return (
        <div className="manage-dialog-form-field block-search-field">
            <label htmlFor={name}>Powiązany dokument</label>
            <div className="select-field">
                <Select
                    className="search-select"
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    options={options}
                    onChange={
                        (option) => handleSelectChange(name, option.value)
                    }
                />
            </div>
        </div>
    )
}

const DateField = ({ param, onChange }) => {
    const { name, value, error } = param

	const handleInputChange = ({ name, value }) => {
		onChange({ name, value })
	}

    return (
        <div className="manage-dialog-form-field">
            <label>Data przelewu</label>
            <DateEditor
                wrapperClassName={error ? 'form-field-error' : ''}
                innerHtml={error ? <span className="form-field-icon">!</span> : null}
                name={name}
                selected={value}
                onChange={handleInputChange}
            />
        </div>
    )
}

const TransactionForm = ({ organization, btnText, onCancel }) => {
    const dispatch = useDispatch()

    const directions = useSelector(state => state.manage.directions)
    const transaction = useSelector(state => state.manage.transaction)
    const myDocuments = useSelector(state => state.manage.myDocuments)

    const [data, setData] = useState({
        id: null,
        direction: 1,
        contractor: '',
        account: '',
        title: '',
        category_id: null,
        postal_code: '',
        location: '',
        address: '',
        amount: 0,
        currency: 'pln',
        document_id: null,
        datetime: null
    })

	const [errors, setErrors] = useState({
		direction: false,
		contractor: false,
		account: false,
		title: false,
		category_id: false,
		postal_code: false,
		location: false,
		address: false,
		amount: false,
		currency: false,
        document_id: false,
        datetime: false
	})

    const [directionOptions, setDirectionOptions] = useState([])

    useEffect(() => {
        if (directions) {
            setDirectionOptions(Object.values(directions).map(
                d => ({ label: d.singular_name, value: d.index })
            ))
        }
    }, [directions])

    useEffect(() => {
        if (transaction) {
            console.log('transaction', transaction)
            const {
                id,
                direction_index: direction,
                contractor,
                account,
                title,
                category_id,
                postal_code,
                location,
                address,
                amount,
                currency,
                document_id,
                datetime
            } = transaction

            setData({
                id,
                direction,
                contractor,
                account,
                title,
                category_id,
                postal_code,
                location,
                address,
                amount,
                currency,
                document_id,
                datetime
            })
        }
    }, [transaction])

    const [documentsOptions, setDocumentsOptions] = useState([])

    useEffect(() => {
        setDocumentsOptions(Object.values(myDocuments).map(
            d => ({ label: `${d.type} - ${d.name}`, value: d.id })
        ))
    }, [myDocuments])

    const mainParams = [
        {
            field: 'select',
            name: 'direction',
            label: 'Typ przelewu',
            placeholder: 'wybierz',
            value: directionOptions.find(
                item => item.value === data.direction
            ),
            options: directionOptions,
            error: errors.direction
        },
        {
            field: 'input-text',
            name: 'contractor',
            label: 'Nazwa kontrachenta',
            placeholder: 'wpisz',
            value: data.contractor,
            error: errors.contractor
        },
        {
            field: 'input-mask',
            name: 'account',
            label: 'Numer konta',
            mask: 'PL 99 9999 9999 9999 9999 9999 9999',
            placeholder: 'PL 00 0000 0000 0000 0000 0000 0000',
            value: data.account,
            error: errors.account
        },
        {
            field: 'input-text',
            name: 'title',
            label: 'Tytuł przelewu',
            placeholder: 'wpisz tytuł',
            value: data.title,
            error: errors.title
        },
        {
            field: 'select',
            name: 'category_id',
            label: 'Kategoria ' + (directions 
                ? directions[data.direction].genitive_name 
                : ''
            ),
            placeholder: 'wybierz kategorię z listy',
            value: directions ? directions[data.direction].categories.map(
                c => ({ label: c.name, value: c.id })
            ).find(
                item => item.value === data.category_id
            ) : null,
            options: directions ? directions[data.direction].categories.map(
                c => ({ label: c.name, value: c.id })
            ) : [],
            error: errors.category_id
        }
    ]

    const addressParams = [
        {
            field: 'input-mask',
            name: 'postal_code',
            mask: '99-999',
            placeholder: '00-000',
            value: data.postal_code,
            size: 'sm',
            error: errors.postal_code
        },
        {
            field: 'input-text',
            name: 'location',
            placeholder: 'miejcowość',
            value: data.location,
            size: 'md',
            error: errors.location
        },
        {
            field: 'input-text',
            name: 'address',
            placeholder: 'ulica',
            value: data.address,
            size: 'fw',
            error: errors.address
        }
    ]

    const amountParams = [
        {
            field: 'input-number',
            name: 'amount',
            placeholder: '00000',
            value: data.amount,
            size: 'xs',
            error: errors.amount
        },
        {
            field: 'select',
            name: 'currency',
            label: 'Waluta',
            placeholder: 'PLN',
            value: currencies.find(
                item => item.value === data.currency
            ),
            options: currencies,
            size: 'xs',
            error: errors.currency
        }
    ]

    const documentParam = {
        name: 'document_id',
        placeholder: 'wybierz dokument z listy',
        value: documentsOptions.find(
            item => item.value === data.document_id
        ),
        options: documentsOptions,
        error: errors.document_id
    }

    const dateParam = {
        name: 'datetime',
        value: data.datetime,
        error: errors.datetime
    }

    const onChange = ({ name, value }) => {
        setData({ ...data, [name]: value })
        setErrors({ ...errors, [name]: false })
    }

	const setError = (name) => {
		setErrors({ ...errors, [name]: true })
		return false
	}

	const validateForm = async (formData) => {
		try {
			await transactionSchema.validate(formData, { abortEarly: false })
			return true
		} catch (errors) {
			// console.error(errors.inner)
			let error = errors.inner[0]
			return setError(error.params.path)
		}
	}

    const onSubmit = async () => {
	    const formValid = await validateForm(data)
        if (formValid === true) {
            const payload = {
                organization_id: organization.id,
                ...data,
                datetime: formatDate(data.datetime)
            }
            console.log(payload)
            await dispatch(saveTransaction(payload))
            await dispatch(getMyOrganizationById(organization.id))
            onCancel()
        }
    }

    return (
        <div className="manage-dialog-form transaction-form">
            <MainFields params={mainParams} onChange={onChange} />
            <AddressFields params={addressParams} onChange={onChange} />
            <AmountFields params={amountParams} onChange={onChange} />
            <DocumentField param={documentParam} onChange={onChange} />
            <DateField param={dateParam} onChange={onChange} />
            <div className="transaction-info">
                WAŻNE:<br />
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod incidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. 
            </div>
            <div className="transaction-accept">
                <input type="checkbox" id="accept" />
                <label htmlFor="accept">
                    Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
                </label>
            </div>
            <Buttons btnText={btnText} onCancel={onCancel} onSubmit={onSubmit}/>
        </div>
    )
}

export default TransactionForm
