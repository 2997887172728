import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthPage } from '@/actions/content'
import { setAccountType } from '@/actions/user'
import { useLocation } from 'react-router-dom'
import PageLoader from '@/components/Common/Loader/PageLoader'
import SelectAccountType from '@/components/Auth/Form/SelectAccountType'
import BlockEditUser from '@/components/User/BlockEditUser'
import BlockEditAbout from '@/components/User/BlockEditAbout'
import BlockEditInterests from '@/components/User/BlockEditInterests'
import BlockEditWorks from '@/components/User/BlockEditWorks'
import BlockEditProjects from '@/components/User/BlockEditProjects'
import BlockEditAchievements from '@/components/User/BlockEditAchievements'
import BlockEditSkills from '@/components/User/BlockEditSkills'

const UserEditPage = ({ user, onLayoutChanged }) => {
  	const dispatch = useDispatch()
	const location = useLocation()
	const params = new URLSearchParams(location.search)
  	const block = params.get('block')

    const [loaded, setLoaded] = useState(false)

	const authPage = useSelector(state => state.content.authPage)

	const [texts, setTexts] = useState({ title: '', subtitle: '', connected_pages: {} })

	useEffect(() => {
		dispatch(getAuthPage('/register'))
	}, [dispatch])

	useEffect(() => {
		if (authPage) {
			const { connected_pages } = authPage

			setTexts({ ...texts, connected_pages })
		}
	}, [authPage])

	const [accType, setAccType] = useState(null)

	const onAccountTypeSelected = async (account_type) => {
		await dispatch(setAccountType({ account_type }))
		setAccType(account_type)
		setLayout(account_type)
	}

	const setLayout = (account_type) => {
		if (account_type !== null) {
			onLayoutChanged('profile')
		} else {
			onLayoutChanged('auth')
		}
	}

	useEffect(() => {
		if (user) {
			setAccType(user.account_type)
		}
	}, [user])

	useEffect(() => {
		if (user && authPage) {
			setLoaded(true)
		}
	}, [user, authPage])

	useEffect(() => {
		if (block) {
			const element = document.getElementById(block)
			if (element) {
				setTimeout(() => window.scroll({ top: element.offsetTop, behavior: 'smooth' }), 500)
			}
		}
	}, [block])

	const onSave = () => {
		
	}

	return (
		<>
			<PageLoader loaded={loaded} />
			{!accType && <div className="services-section">
				<div className="container">
					<SelectAccountType
						texts={texts.connected_pages['select-account-type']}
						onAccountTypeSelected={onAccountTypeSelected}
					/>
				</div>
			</div>}
			{accType && <>
				<BlockEditUser user={user} onSave={onSave} />
				<BlockEditAbout user={user} onSave={onSave} />
				<BlockEditInterests user={user} onSave={onSave} />
				<BlockEditWorks user={user} onSave={onSave} />
				<BlockEditProjects user={user} onSave={onSave} />
				<BlockEditAchievements user={user} onSave={onSave} />
				<BlockEditSkills user={user} onSave={onSave} />
			</>}
		</>
	)
}

export default UserEditPage
