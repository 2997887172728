import { useState, useEffect } from 'react'
import { formatTableDate } from '@/utils/datetime.utils'

const TransactionItem = ({ item, onEdit }) => {
    const { datetime, title, direction, category, amount, status } = item
    const sign = direction === 'income' ? '+' : '-'

    const handleItemClick = () => {
        onEdit()
    }

	return (
		<div className="block-transactions-table-row" onClick={handleItemClick}>
			<div>{formatTableDate(datetime)}</div>
			<div
                className={`block-transactions-table-cell-direction status-${status} direction-${direction}`}
            >
                <span>{sign}</span> przelew
            </div>
            <div>{title}</div>
            <div>{category.name || null}</div>
            <div className={`block-transactions-table-cell-amount amount-${direction}`}>
                {sign} {Intl.NumberFormat('pl-PL', {
                    style: 'decimal',
                    useGrouping: true,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(amount)}
            </div>
            <div></div>
            <div></div>
		</div>
	)
}

const BlockTransactions = ({ organization, onEdit }) => {
    const [transactions, setTransactions] = useState([])

    useEffect(() => {
        if (organization) {
            setTransactions(organization.transactions)
        }
    }, [organization])

    const editTransaction = (item) => {
        onEdit(item.id)
    }

	return (
		<div className="block-transactions">
            <div className="block-transactions-title">Historia transakcji</div>
			<div className="block-transactions-table">
				<div className="block-transactions-table-row block-transactions-table-head">
					<div>data</div>
					<div>typ</div>
					<div>opis</div>
					<div>kategoria</div>
					<div>kwota</div>
                    <div>saldo</div>
                    <div>szczegóły</div>
                </div>
                {transactions.map((item, index) => (
                    <TransactionItem item={item} key={index} onEdit={() => onEdit(item)} />
                ))}
			</div>
		</div>
	)
}

export default BlockTransactions
