import * as yup from 'yup'

const documentSchema = yup.object().shape({
    name: yup.string()
        .required(),
    type_id: yup.number()
        .required(),
    contractor: yup.string()
        .required(),
})

export default documentSchema
