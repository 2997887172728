import {
  GET_VIEW_PROJECT_REQUEST,
  GET_VIEW_PROJECT_SUCCESS,
  GET_VIEW_PROJECT_FAILURE,
  GET_MY_PROJECT_REQUEST,
  GET_MY_PROJECT_SUCCESS,
  GET_MY_PROJECT_FAILURE,
  SAVE_PROJECT_SUCCESS,
  SAVE_PROJECT_FAILURE,
} from '../actions/project'

const initialState = {
  viewProjectLoading: true,
  viewProject: null,
  myProjectLoading: true,
  myProject: null,
  error: null,
}

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIEW_PROJECT_REQUEST:
      return {
        ...state,
        viewProjectLoading: true
      }
    case GET_VIEW_PROJECT_SUCCESS:
      return {
        ...state,
        viewProjectLoading: false,
        viewProject: action.payload,
        error: null
      }
    case GET_VIEW_PROJECT_FAILURE:
      return {
        ...state,
        viewProjectLoading: false,
        viewProject: null,
        error: action.payload
      }
    case GET_MY_PROJECT_REQUEST:
      return {
        ...state,
        myProjectLoading: true
      }
    case GET_MY_PROJECT_SUCCESS:
      return {
        ...state,
        myProjectLoading: false,
        myProject: action.payload,
        error: null
      }
    case GET_MY_PROJECT_FAILURE:
      return {
        ...state,
        myProjectLoading: false,
        myProject: null,
        error: action.payload
      }
    case SAVE_PROJECT_SUCCESS:
      return {
        ...state,
        error: null
      }
    case SAVE_PROJECT_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state
  }
}

export default projectReducer
