import * as yup from 'yup'

const transactionSchema = yup.object().shape({
    direction: yup.number()
        .required(),
    contractor: yup.string()
        .required(),    
    account: yup.string()
        .required()
        .test('account', value => {
            if (value) {
                const unfilledChars = value.split('_').length - 1
                return unfilledChars === 0
            }
            return false
        }),
    title: yup.string()
        .required(),
    category_id: yup.number()
        .required(),
    postal_code: yup.string()
        // .required()
        .test('postal_code', value => {
            if (value) {
                const unfilledChars = value.split('_').length - 1
                return unfilledChars === 0
            }
            return true
        }),
    // location: yup.string()
    //     .required(),
    // address: yup.string()
    //     .required(),
    amount: yup.number()
        .required()
        .min(1),
    currency: yup.string()
        .required(),
    datetime: yup.string()
        .required(),
})

export default transactionSchema
