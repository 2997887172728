import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { editTransaction } from '@/actions/manage'
import { useNavigate } from 'react-router-dom'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import BlockBudget from './BlockBudget'
import BlockActions from './BlockActions'
import BlockTransactions from './BlockTransactions'
import ManageDialog from './ManageDialog'

import './styles.css'

const ManageBlock = ({ organization }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const getImage = (image) => {
		if (image) {
			return `${STORAGE_URL}/${image}` 
		}
		return userDefault
	}

    const goBack = () => {
        navigate(-1)
    }

	const [dialog, setDialog] = useState({ open: false, action: null })

	const onActionSelected = (action) => {
		if (action === 'edit_transaction') {
			dispatch(editTransaction(null))
		}
		setDialog({ open: true, action })
	}

	const onTransactionEdit = (transaction) => {
		dispatch(editTransaction(transaction))
		setDialog({ open: true, action: 'edit_transaction' })
	}

	const onDialogClose = () => {
		setDialog({ open: false, action: null })
	}

	if (!organization) return

	return (
		<>
		<div className="block block-search">
			<div className="block-close">
				<a style={{ cursor: 'pointer' }} onClick={goBack}><span></span></a>
			</div>
			<div className="block-wrap">
				<div className="block-manage-head">
					<div className="block-manage-logo">
						<img src={getImage(organization.logo_image)} alt="" />
					</div>
					<div className="block-search-heading">
						<h1>Panel zarządzania {organization.name}</h1>
						<h2>Zarządzaj finansami i administracją</h2>
					</div>
				</div>
                <BlockBudget organization={organization} />
				<BlockActions onSelected={onActionSelected} />
				<BlockTransactions organization={organization} onEdit={onTransactionEdit} />			
				<div className="block-search-btn">
					<button className="btn btn-thirdy" onClick={goBack}>ZAMKNIJ PANEL</button>
				</div>
			</div>
		</div>
		{organization && (
			<ManageDialog
				organization={organization}
				dialog={dialog}
				onClose={onDialogClose}
			/>
		)}
		</>
	)
}

export default ManageBlock
