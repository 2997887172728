const Buttons = ({ btnText = 'WYŚLIJ', onCancel, onSubmit }) => {
    return (
        <div className="manage-dialog-buttons">
            <button
                type="button"
                className="btn btn-outline-primary"
                onClick={onCancel}
            >
                <span>ANULUJ</span>
            </button>
            <button
                type="button"
                className="btn btn-primary btn-icon-right btn-icon-check"
                onClick={onSubmit}
            >
                <span>{btnText}</span>
            </button>
        </div>
    )
}

export default Buttons
