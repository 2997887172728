import OfferService from '@/services/offer.service'
import { saveSuccess, saveError } from '@/utils/notify.utils'

export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE'
export const GET_ACTIVITY_TYPES_SUCCESS = 'GET_ACTIVITY_TYPES_SUCCESS'
export const GET_ACTIVITY_TYPES_FAILURE = 'GET_ACTIVITY_TYPES_FAILURE'
export const SEARCH_OFFERS_REQUEST = 'SEARCH_OFFERS_REQUEST'
export const SEARCH_OFFERS_SUCCESS = 'SEARCH_OFFERS_SUCCESS'
export const SEARCH_OFFERS_FAILURE = 'SEARCH_OFFERS_FAILURE'
export const GET_OFFER_REQUEST = 'GET_OFFER_REQUEST'
export const GET_OFFER_SUCCESS = 'GET_OFFER_SUCCESS'
export const GET_OFFER_FAILURE = 'GET_OFFER_FAILURE'
export const GET_MY_OFFERS_REQUEST = 'GET_MY_OFFERS_REQUEST'
export const GET_MY_OFFERS_SUCCESS = 'GET_MY_OFFERS_SUCCESS'
export const GET_MY_OFFERS_FAILURE = 'GET_MY_OFFERS_FAILURE'
export const CREATE_OFFER_SUCCESS = 'CREATE_OFFER_SUCCESS'
export const CREATE_OFFER_FAILURE = 'CREATE_OFFER_FAILURE'
export const UPDATE_OFFER_SUCCESS = 'UPDATE_OFFER_SUCCESS'
export const UPDATE_OFFER_FAILURE = 'UPDATE_OFFER_FAILURE'

export const getCitiesSuccess = (data) => ({ type: GET_CITIES_SUCCESS, payload: data })
export const getCitiesFailure = (error) => ({ type: GET_CITIES_FAILURE, payload: error })
export const getActivityTypesSuccess = (data) => ({ type: GET_ACTIVITY_TYPES_SUCCESS, payload: data })
export const getActivityTypesFailure = (error) => ({ type: GET_ACTIVITY_TYPES_FAILURE, payload: error })
export const searchOffersRequest = () => ({ type: SEARCH_OFFERS_REQUEST })
export const searchOffersSuccess = (data) => ({ type: SEARCH_OFFERS_SUCCESS, payload: data })
export const searchOffersFailure = (error) => ({ type: SEARCH_OFFERS_FAILURE, payload: error })
export const getOfferRequest = () => ({ type: GET_OFFER_REQUEST })
export const getOfferSuccess = (data) => ({ type: GET_OFFER_SUCCESS, payload: data })
export const getOfferFailure = (error) => ({ type: GET_OFFER_FAILURE, payload: error })
export const getMyOffersRequest = () => ({ type: GET_MY_OFFERS_REQUEST })
export const getMyOffersSuccess = (data) => ({ type: GET_MY_OFFERS_SUCCESS, payload: data })
export const getMyOffersFailure = (error) => ({ type: GET_MY_OFFERS_FAILURE, payload: error })
export const createOfferSuccess = () => ({ type: CREATE_OFFER_SUCCESS })
export const createOfferFailure = (error) => ({ type: CREATE_OFFER_FAILURE, payload: error })
export const updateOfferSuccess = () => ({ type: UPDATE_OFFER_SUCCESS })
export const updateOfferFailure = (error) => ({ type: UPDATE_OFFER_FAILURE, payload: error })

export const getCities = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OfferService.getCities(token)
    console.log(result)
    if (result.success) {
      dispatch(getCitiesSuccess(result.cities))
    } else {
      dispatch(getCitiesFailure(result.message))
    }
  } catch (e) {
    dispatch(getCitiesFailure(e.toString()))
  }
}

export const getActivityTypes = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OfferService.getActivityTypes(token)
    console.log(result)
    if (result.success) {
      dispatch(getActivityTypesSuccess(result.activity_types))
    } else {
      dispatch(getActivityTypesFailure(result.message))
    }
  } catch (e) {
    dispatch(getActivityTypesFailure(e.toString()))
  }
}

export const searchOffers = (payload) => async (dispatch) => {
  try {
    dispatch(searchOffersRequest())
    const token = localStorage.getItem('token')
    const result = await OfferService.searchOffers(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(searchOffersSuccess(result.offers))
    } else {
      dispatch(searchOffersFailure(result.message))
    }
  } catch (e) {
    dispatch(searchOffersFailure(e.toString()))
  }
}

export const getOffer = (id) => async (dispatch) => {
  try {
    dispatch(getOfferRequest())
    const token = localStorage.getItem('token')
    const result = await OfferService.getOffer(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getOfferSuccess(result.offer))
    } else {
      dispatch(getOfferFailure(result.message))
    }
  } catch (e) {
    dispatch(getOfferFailure(e.toString()))
  }
}

export const getMyOffers = () => async (dispatch) => {
  try {
    dispatch(getMyOffersRequest())
    const token = localStorage.getItem('token')
    const result = await OfferService.getMyOffers(token)
    console.log(result)
    if (result.success) {
      dispatch(getMyOffersSuccess(result.offers))
    } else {
      dispatch(getMyOffersFailure(result.message))
    }
  } catch (e) {
    dispatch(getMyOffersFailure(e.toString()))
  }
}

export const createOffer = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OfferService.createOffer(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(createOfferSuccess())
      saveSuccess()
    } else {
      dispatch(createOfferFailure(result.error))
      saveError(result.error)
    }
    return result.success
  } catch (e) {
    dispatch(createOfferFailure(e.toString()))
    saveError(e.toString())
    return false
  }
}

export const updateOffer = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OfferService.updateOffer(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(updateOfferSuccess())
      saveSuccess()
    } else {
      dispatch(updateOfferFailure(result.error))
      saveError(result.error)
    }
    return result.success
  } catch (e) {
    dispatch(updateOfferFailure(e.toString()))
    saveError(e.toString())
    return false
  }
}

export const updateOfferParam = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OfferService.updateOfferParam(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(updateOfferSuccess())
      saveSuccess()
    } else {
      dispatch(updateOfferFailure(result.error))
      saveError(result.error)
    }
    return result.success
  } catch (e) {
    dispatch(updateOfferFailure(e.toString()))
    saveError(e.toString())
    return false
  }
}
