import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDialogs, searchDialogs } from '@/actions/chat'
import arrow from '@/assets/images/search-arrow-down.svg'
import search from '@/assets/images/search.svg'
import ChatUserItem from './ChatUserItem'

const ChatUsers = ({ selectedDialog, onSelect }) => {
	const dispatch = useDispatch()

	const userDialogs = useSelector(state => state.chat.userDialogs)
	const foundDialogs = useSelector(state => state.chat.foundDialogs)

	const [query, setQuery] = useState('')
	const [dialogs, setDialogs] = useState([])

	useEffect(() => {
		dispatch(getDialogs())
	}, [dispatch])

	const handleInputChange = (e) => {
		const { value: search } = e.target
		dispatch(searchDialogs({ search }))
		setQuery(search)
	}

	useEffect(() => {
		if (foundDialogs.length) {
			setDialogs(foundDialogs)
		} else {
			setDialogs(userDialogs)
		}
	}, [userDialogs, foundDialogs])

	const isSelected = (userId) => {
		return selectedDialog && selectedDialog.collocutor.user_id === userId
	}

	const selectDialog = (dialogId) => {
		onSelect(dialogId)
	}

	useEffect(() => {
		setDialogs(
			prevDialogs => prevDialogs.map(
				dialog => dialog.id === selectedDialog.id 
						? ({ ...dialog, unread: selectedDialog.unread }) 
						: dialog
			)
		)
	}, [selectedDialog])

	return (
		<div className={`block block-chat-users ${selectedDialog ? 'collapsed' : ''}`}>
			<div className="block-wrap">
				<div className="block-chat-users-search">
					{/*<span className="form-field-arrow">*/}
						<input
							type="search"
							placeholder="wpisz poszukiwany wyraz"
							name="search"
							value={query}
							onChange={handleInputChange}
						/>
						{/*<span className="button"><img src={arrow} alt="" /></span>*/}
					{/*</span>*/}
					<img src={search} alt="" />
				</div>
				<div className="block-chat-users-list">
					{dialogs.map((dialog, index) => {
						const { id, collocutor: user, unread } = dialog
						return <ChatUserItem
							position="users"
							user={user}
							isSelected={isSelected(user.user_id)}
							isUnread={unread > 0}
							key={index}
							selectUser={() => selectDialog(id)}
						/>
					})}
				</div>
			</div>
		</div>
	)
}

export default ChatUsers
