import * as yup from 'yup'
// import * as C from '../constants/auth'

const minPasswordLength = 6
const maxPasswordLength = 32

const registerSchema = yup.object().shape({
  firstname: yup.string()
    .required(),
  lastname: yup.string()
    .required(),
  email: yup.string()
    .email()
    .required(),
  password: yup.string()
    .min(minPasswordLength, '')
    .max(maxPasswordLength, '')
    .required(),
  password_confirmation: yup.string()
    .oneOf([yup.ref('password'), null], ''),
  policy: yup.boolean()
    .oneOf([true], ''),
})

export default registerSchema
