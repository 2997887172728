import { Link } from 'react-router-dom'
import DataLoader from '@/components/Common/Loader/DataLoader'
import { STORAGE_URL } from '@/constants/api'
import { resultTypes } from '@/constants/search'
import userDefault from '@/assets/images/user-default.png'

const ResultItem = ({ item }) => {
	const getImage = (logo_image) => {
		if (logo_image) {
			return `${STORAGE_URL}/${logo_image}` 
		}
		return userDefault
	}

	const getLink = () => {
		return `/${item.type}/profile/${item.id}`
	}

	return (
		<div className="block-search-resultsItem">
			<p><img src={getImage(item.logo_image)} width="" height="" alt="" /></p>
			<p>{item.name}</p>
			<p>{resultTypes[item.type]}</p>
			<p>{item.activity_type}</p>
			<p><Link to={getLink()} className="block-search-resultsItem__view"></Link></p>
		</div>
	)
}

const SearchResults = ({ results, loaded }) => {
	return (
		<div className="block-search-results">
            <DataLoader loaded={loaded} />
			<div className="block-search-resultsItems">
				<div className="block-search-resultsItem block-search-resultsItem__head">
					<p>logo/awatar</p>
					<p>nazwa/nazwisko</p>
					<p>typ</p>
					<p>obszar działania</p>
					<p>zobacz profil</p>
				</div>
				{results.map((item, index) => <ResultItem item={item} key={index} />)}
			</div>
		</div>
	)
}

export default SearchResults
