import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { uploadImage, saveInfo } from '@/actions/project'
import { checkImageType, ERROR_NOT_ALLOWED_IMAGE } from '@/utils/upload.utils'
import { saveError } from '@/utils/notify.utils'
import { STORAGE_URL } from '@/constants/api'
import ImageLoader from '@/components/Common/Loader/ImageLoader'
import ConfirmButton from '@/components/Common/Edit/ConfirmButton'

import './styles.css'

const UploadImage = ({ image, type, btnText, projectId }) => {
	const dispatch = useDispatch()
    const imageUploadInputRef = useRef()

    const [currentImage, setCurrentImage] = useState(
    	'linear-gradient(180deg, #5C8CC9 0%, #63B2E3 100%)'
    )
    const [loaded, setLoaded] = useState(true)

    useEffect(() => {
    	if (image) {
    		setCurrentImage(`url(${STORAGE_URL}/${image})`)
    	}
    }, [image])

    const handleImageUploadClick = () => {
        // setImageError(null)
        imageUploadInputRef.current.click()
    }

    const handleImageUpload = async (e) => {
        const image = e.target.files[0]
        if (checkImageType(image.type)) {
    		setLoaded(false)
	        const formData = new FormData()
	        formData.append('type', type)
	        formData.append('image', image)
	        formData.append('project_id', projectId)
            const imageData = await dispatch(uploadImage(formData, projectId))
    		setCurrentImage(`url(${STORAGE_URL}/${imageData.image})`)
    		setTimeout(() => setLoaded(true), 500)
        } else {
	        saveError(ERROR_NOT_ALLOWED_IMAGE)
	    }
    }

	return (
		<div
			className={`block-editProject_head-image block-editProject_head-image-${type}`}
			style={{
				position: 'relative',
				background: `${currentImage} center/cover no-repeat`
			}}
		>
			<ImageLoader loaded={loaded} />
			<a className="btn btn-outline-secondary" onClick={handleImageUploadClick}>{btnText}</a>
            <input
                type="file"
                ref={imageUploadInputRef}
                style={{ display: 'none' }}
                onChange={handleImageUpload}
            />
		</div>
	)
}

const HeadFields = ({ params, onInputChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onInputChange({ name, value })
    }

	return (
		<div className="block-editProfile_head-fields block-editProject_head-fields">
			<div className="form-fields">
			{params.map((param, index) => {
				const { name, label, value, field } = param
				return (
					<div className="form-field" key={index}>
						{field === 'input' && <input
							type="text"
							name={name}
							placeholder={label}
							value={value}
							onChange={handleInputChange}
						/>}
						{field === 'textarea' && <textarea
							name={name}
							placeholder={label}
							value={value}
							onChange={handleInputChange}
						></textarea>}
					</div>
				)
			})}
			</div>
		</div>
	)
}

const BlockEditInfo = ({ project }) => {
	const dispatch = useDispatch()

	const [images, setImages] = useState({
		background_image: null,
		logo_image: null,
	})

	const [data, setData] = useState({
		project_id: null,
		name: '',
		website: '',
		description: '',
	})

    const [errors, setErrors] = useState({
        name: false,
        website: false,
        description: false,
    })

	useEffect(() => {
		if (project) {
			const {
				id: project_id,
				background_image,
				logo_image,
				name,
				website,
				description,
			} = project

			setImages({
				background_image,
				logo_image,
			})

			setData({
				project_id: project_id || null,
				name: name || '',
				website: website || '',
				description: description || '',
			})
		}
	}, [project])

	const headParams = [
		{ name: 'website', label: 'adres www', value: data.website, error: errors.website, field: 'input' },
		{ name: 'name', label: 'Nazwa organizacji - wpisz maks 130 znaków', value: data.name, error: errors.name, field: 'textarea' },
		{ name: 'description', label: 'Krótki opis działalności - max 280 znaków', value: data.description, error: errors.description, field: 'textarea' },
	]

	const onInputChange = ({ name, value }) => {
		setData({ ...data, [name]: value })
        // setErrors({ ...errors, [name]: false })
	}

	const onSubmit = async () => {
		/*const names = parseFullName(data.fullname)
		if (names) {
			const payload = { ...data, ...names, socials }
			// console.log(payload)
			await dispatch(saveProfile(payload))
			onSave()
		}*/
		await dispatch(saveInfo(data))
	}

	return (
		<form className="block block-editProfile block-editProject">
			<div className="block-editProfile_head block-editProject_head">
				<UploadImage
					image={images.logo_image}
					type="logo"
					btnText="DODAJ ZDJĘCIE AWATAR"
					projectId={data.project_id}
				/>
				<UploadImage
					image={images.background_image}
					type="background"
					btnText="DODAJ ZDJĘCIE"
					projectId={data.project_id}
				/>
				{/*<div className="block-singleProject_head-left">*/}
					{/*<div className="block-singleProject_head-image-1">*/}
						{/*<img src={getImage('logo')} width="" height="" alt="" />*/}
					{/*</div>*/}
				{/*</div>*/}
				{/*<div className="block-singleProject_head-right">
					<div className="block-singleProject_head-image-2">

					</div>
				</div>*/}
				<HeadFields params={headParams} onInputChange={onInputChange} />
			</div>
			<ConfirmButton onSubmit={onSubmit} />
		</form>
	)
}

export default BlockEditInfo
