import './styles.css'
import loader from '@/assets/images/loader.gif'

const ImageLoader = ({ loaded }) => {
	return (
		<div className={`image-loader image-loader${loaded ? '-loaded' : ''}`}>
			<img src={loader} alt="" />
		</div>
	)
}

export default ImageLoader
