import SearchService from '@/services/search.service'

export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS'
export const GET_OPTIONS_FAILURE = 'GET_OPTIONS_FAILURE'
// export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
// export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE'
// export const GET_AREAS_SUCCESS = 'GET_AREAS_SUCCESS'
// export const GET_AREAS_FAILURE = 'GET_AREAS_FAILURE'
// export const GET_ACTIVITY_TYPES_SUCCESS = 'GET_ACTIVITY_TYPES_SUCCESS'
// export const GET_ACTIVITY_TYPES_FAILURE = 'GET_ACTIVITY_TYPES_FAILURE'
export const SEARCH_DATA_REQUEST = 'SEARCH_DATA_REQUEST'
export const SEARCH_DATA_SUCCESS = 'SEARCH_DATA_SUCCESS'
export const SEARCH_DATA_FAILURE = 'SEARCH_DATA_FAILURE'

export const getOptionsSuccess = (data) => ({ type: GET_OPTIONS_SUCCESS, payload: data })
export const getOptionsFailure = (error) => ({ type: GET_OPTIONS_FAILURE, payload: error })
// export const getCitiesSuccess = (data) => ({ type: GET_CITIES_SUCCESS, payload: data })
// export const getCitiesFailure = (error) => ({ type: GET_CITIES_FAILURE, payload: error })
// export const getAreasSuccess = (data) => ({ type: GET_AREAS_SUCCESS, payload: data })
// export const getAreasFailure = (error) => ({ type: GET_AREAS_FAILURE, payload: error })
// export const getActivityTypesSuccess = (data) => ({ type: GET_ACTIVITY_TYPES_SUCCESS, payload: data })
// export const getActivityTypesFailure = (error) => ({ type: GET_ACTIVITY_TYPES_FAILURE, payload: error })
export const searchDataRequest = () => ({ type: SEARCH_DATA_REQUEST })
export const searchDataSuccess = (data) => ({ type: SEARCH_DATA_SUCCESS, payload: data })
export const searchDataFailure = (error) => ({ type: SEARCH_DATA_FAILURE, payload: error })

export const getOptions = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await SearchService.getOptions(token)
    console.log(result)
    if (result.success) {
      dispatch(getOptionsSuccess(result))
    } else {
      dispatch(getOptionsFailure(result.message))
    }
  } catch (e) {
    dispatch(getOptionsFailure(e.toString()))
  }
}

/*export const getCities = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await SearchService.getCities(token)
    console.log(result)
    if (result.success) {
      dispatch(getCitiesSuccess(result.cities))
    } else {
      dispatch(getCitiesFailure(result.message))
    }
  } catch (e) {
    dispatch(getCitiesFailure(e.toString()))
  }
}

export const getAreas = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await SearchService.getAreas(token)
    console.log(result)
    if (result.success) {
      dispatch(getAreasSuccess(result.areas))
    } else {
      dispatch(getAreasFailure(result.message))
    }
  } catch (e) {
    dispatch(getAreasFailure(e.toString()))
  }
}

export const getActivityTypes = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await SearchService.getActivityTypes(token)
    console.log(result)
    if (result.success) {
      dispatch(getActivityTypesSuccess(result.activity_types))
    } else {
      dispatch(getActivityTypesFailure(result.message))
    }
  } catch (e) {
    dispatch(getActivityTypesFailure(e.toString()))
  }
}*/

export const searchData = (payload) => async (dispatch) => {
  try {
    dispatch(searchDataRequest())
    const token = localStorage.getItem('token')
    const result = await SearchService.searchData(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(searchDataSuccess(result.results))
    } else {
      dispatch(searchDataFailure(result.message))
    }
  } catch (e) {
    dispatch(searchDataFailure(e.toString()))
  }
}
