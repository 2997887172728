const colors = [
    "FF6666", // ніжний червоний
    "FF9966", // м'який оранжево-рожевий
    "FFCC66", // світлий жовто-оранжевий
    "FFFF99", // пастельний жовтий
    "CCFF99", // м'який салатовий
    "99FFCC", // світлий м'ятний
    "99CCFF", // ніжний блакитний
    "9999FF", // м'який синьо-фіолетовий
    "CC99FF", // пастельний фіолетовий
    "FF99CC"  // м'який рожевий-фіолетовий
]

const ChartLine = ({ category, index }) => {
    const { relative_percentage: p } = category
    const bgColorIndex = index % colors.length
    const bgColor = colors[bgColorIndex]
    return (
        <span style={{ background: `#${bgColor}`, width: `${p}%` }} key={index}></span>
    )
}

export default ChartLine
