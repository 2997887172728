import api from './api'

class ProjectService
{	
	async getProjectById(id, token) {
		try {
			const response = await api.get(`/project/one/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][getProjectById][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyProjectById(id, token) {
		try {
			const response = await api.get(`/project/my/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][getMyProjectById][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async uploadImage(payload, token) {
		try {
			const response = await api.post('/project/upload-image', payload, {
	            headers: { 
	            	Authorization: `Bearer ${token}`,
	            	'Content-Type': 'multipart/form-data'
	            }
	        })
			return response.data
		} catch (e) {
			console.log('[ProjectService][uploadImage][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveInfo(payload, token) {
		try {
			const response = await api.post('/project/save-info', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ProjectService][saveInfo][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveProject(payload, token) {
		try {
			const response = await api.post('/project/save', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ProjectService][saveProject][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveAbout(payload, token) {
		try {
			const response = await api.post('/project/save-about', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ProjectService][saveAbout][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveGoals(payload, token) {
		try {
			const response = await api.post('/project/save-goals', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ProjectService][saveGoals][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async savePartner(payload, token) {
		try {
			const response = await api.post('/project/save-partner', payload, {
	            headers: {
	            	Authorization: `Bearer ${token}`,
	            	'Content-Type': 'multipart/form-data'
	            }
	        })
			return response.data
		} catch (e) {
			console.log('[ProjectService][savePartner][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveMembers(payload, token) {
		try {
			const response = await api.post('/project/save-members', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ProjectService][saveMembers][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveStage(payload, token) {
		try {
			const response = await api.post('/project/save-stage', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[ProjectService][saveStage][error]', e)
			return { success: false, message: e.toString() }
		}
	}
}

export default new ProjectService()
