import { actions } from '@/constants/manage'

const BlockActions = ({ onSelected }) => {
    return (
        <div className="block-actions">
            {actions.map((item, index) => {
                const { icon, text, btnText, dark, action } = item
                return (
                    <div className={`block-actions-item ${dark ? 'dark' : ''}`} key={index}>
                        <div className="block-actions-item-left">
                            <img src={icon} alt="" />
                        </div>
                        <div className="block-actions-item-right">
                            <div className="block-actions-item-text">{text}</div>
                            <button
                                className="block-actions-item-button"
                                onClick={() => onSelected(action)}
                            >{btnText}</button>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default BlockActions
